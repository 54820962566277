import { EImg, ETag, TTaskWord } from "../shared";

/* TODO: put image todos on corresponding image enum members. */

export const sharedNorwegian: TTaskWord[] = [
  // word "moped" is too old?.
  { word: "ake", image: EImg.SLEDGING },
  { word: "and", image: EImg.DUCK },
  { word: "anis", image: EImg.ANISE },
  { word: "ape", image: EImg.MONKEY },
  { word: "arm", image: EImg.ARM },
  // { word: "au", image: EImg.OUCH, tags: [ETag.DIPHTHONG] },
  // { word: "aula", image: EImg.AULA, tags: [ETag.DIPHTHONG] },
  { word: "avis", image: EImg.NEWSPAPER },

  { word: "bad", image: EImg.BATHROOM },
  { word: "bade", image: EImg.BATHE },
  { word: "bake", image: EImg.BAKE },
  { word: "balje", image: EImg.TUB },
  { word: "bamse", image: EImg.TEDDY_BEAR },
  { word: "banan", image: EImg.BANANA },
  { word: "barn", image: EImg.BABY },
  // { word: "bein", image: EImg.BONE, tags: [ETag.DIPHTHONG] },
  { word: "bever", image: EImg.BEVER },
  { word: "bie", image: EImg.BEE },
  { word: "bil", image: EImg.CAR },
  { word: "bilde", image: EImg.PICTURES },
  { word: "bite", image: EImg.BITE },
  { word: "bjørn", image: EImg.BEAR },
  { word: "blå", image: EImg.BLUE },
  { word: "bok", image: EImg.BOOK },
  { word: "brev", image: EImg.LETTER },
  { word: "bue", image: EImg.BOW },
  { word: "bukse", image: EImg.PANTS },
  { word: "bur", image: EImg.CAGE },
  { word: "by", image: EImg.CITY },
  { word: "bær", image: EImg.STRAWBERRY },
  // { word: "bøye", image: EImg.BEND, tags: [ETag.DIPHTHONG] },
  { word: "bål", image: EImg.CAMPFIRE },
  { word: "båt", image: EImg.BOAT },

  { word: "dam", image: EImg.DAM },
  { word: "die", image: EImg.SUCKLING },
  { word: "do", image: EImg.TOILET },
  { word: "drage", image: EImg.DRAGON },
  { word: "druer", image: EImg.GRAPES },
  { word: "due", image: EImg.DOVE },
  { word: "duk", image: EImg.TABLE_CLOTH },
  { word: "dør", image: EImg.DOOR },
  { word: "dåp", image: EImg.BAPTISM },

  // { word: "eim", image: EImg.SMELL, tags: [ETag.DIPHTHONG] },
  { word: "ekorn", image: EImg.SQUIRREL },
  { word: "elg", image: EImg.MOOSE },
  { word: "elv", image: EImg.RIVER },
  { word: "elev", image: EImg.PUPIL },
  { word: "eple", image: EImg.APPLE },
  { word: "esel", image: EImg.DONKEY },
  { word: "Eva", image: EImg.NAME_EVA, tags: [ETag.PERSON_NAME] },
  { word: "Even", image: EImg.NAME_EVEN, tags: [ETag.PERSON_NAME] },

  { word: "fane", image: EImg.PENNANT },
  { word: "far", image: EImg.FATHER },
  { word: "fasan", image: EImg.PHEASANT },
  { word: "fat", image: EImg.PLATE },
  { word: "fe", image: EImg.FAIRY },
  // { word: "feie", image: EImg.SWEEP, tags: [ETag.DIPHTHONG] },
  { word: "feber", image: EImg.FEVER },
  // { word: "feier", image: EImg.CHIMNEY_SWEEP, tags: [ETag.DIPHTHONG] },
  { word: "fele", image: EImg.VIOLIN },
  { word: "fem", image: EImg.FIVE },
  { word: "fil", image: EImg.FILE },
  { word: "film", image: EImg.FILM },
  { word: "fire", image: EImg.FOUR },
  { word: "fis", image: EImg.FART },
  { word: "fisk", image: EImg.FISH },
  { word: "fjær", image: EImg.FEATHER },
  // { word: "flau", image: EImg.EMBARRASSED, tags: [ETag.DIPHTHONG] },
  { word: "fly", image: EImg.AEROPLANE },
  { word: "fole", image: EImg.FOAL_2 },
  { word: "fot", image: EImg.FOOT },
  { word: "foto", image: EImg.PHOTO },
  { word: "fryse", image: EImg.FREEZE },
  { word: "furu", image: EImg.TREES },
  { word: "fyr", image: EImg.LIGHTHOUSE },

  { word: "gale", image: EImg.CROWING },
  { word: "gaule", image: EImg.SHOUT_1 },
  { word: "gap", image: EImg.GAPE },
  // { word: "gaupe", image: EImg.LYNX, tags: [ETag.DIPHTHONG] },
  { word: "gate", image: EImg.STREET },
  // { word: "gaule", image: EImg.STREET, tags: [ETag.DIPHTHONG] },
  { word: "gitar", image: EImg.GUITAR },
  { word: "gras", image: EImg.GRAS },
  { word: "gris", image: EImg.PIG },
  { word: "gråte", image: EImg.CRY },
  { word: "gul", image: EImg.YELLOW },
  // { word: "gøy", image: EImg.FUN, tags: [ETag.DIPHTHONG] },
  { word: "gå", image: EImg.WALK },
  { word: "gås", image: EImg.GOOSE },

  // { word: "hai", image: EImg.SHARK, tags: [ETag.DIPHTHONG] },
  { word: "hage", image: EImg.GARDEN },
  // { word: "haike", image: EImg.HITCHHIKING, tags: [ETag.DIPHTHONG] },
  { word: "hale", image: EImg.TAIL },
  { word: "hane", image: EImg.ROOSTER },
  { word: "hare", image: EImg.HARE },
  // { word: "hauk", image: EImg.EAGLE, tags: [ETag.DIPHTHONG] },
  { word: "hav", image: EImg.OCEAN },
  // { word: "hei", image: EImg.HI, tags: [ETag.DIPHTHONG] },
  // { word: "heis", image: EImg.ELEVATOR, tags: [ETag.DIPHTHONG] },
  { word: "heks", image: EImg.WITCH },
  { word: "hest", image: EImg.HORSE },
  { word: "heve", image: EImg.RISE },
  { word: "hi", image: EImg.HIBERNATING },
  { word: "horn", image: EImg.HORN },
  { word: "hund", image: EImg.DOG },
  { word: "hus", image: EImg.HOUSE },
  { word: "Håkon", image: EImg.NAME_HAAKON, tags: [ETag.PERSON_NAME] },
  { word: "hår", image: EImg.HAIR },
  { word: "håv", image: EImg.HAND_NET },
  { word: "hæ", image: EImg.HUH }, // Image-word mapping doesn't really make sense?
  { word: "høst", image: EImg.AUTUMN },
  { word: "høne", image: EImg.HEN },
  // { word: "høy", image: EImg.HAY, tags: [ETag.DIPHTHONG] },
  // { word: "høyre", image: EImg.RIGHT, tags: [ETag.DIPHTHONG] },

  { word: "Ida", image: EImg.NAME_IDA, tags: [ETag.PERSON_NAME] },
  { word: "iglo", image: EImg.IGLOO },
  { word: "is", image: EImg.ICE_CUBE },

  { word: "jage", image: EImg.CHASING },
  { word: "jeger", image: EImg.HUNTER },
  { word: "jente", image: EImg.GIRL },
  { word: "jojo", image: EImg.YOYO },
  { word: "judo", image: EImg.JUDO },
  { word: "jul", image: EImg.CHRISTMAS },
  { word: "jåle", image: EImg.LOOK_IN_MIRROR },

  // { word: "kai", image: EImg.DOCK_2, tags: [ETag.DIPHTHONG] },
  { word: "kake", image: EImg.CAKE },
  { word: "kam", image: EImg.COMB },
  { word: "kamel", image: EImg.CAMEL },
  { word: "kanin", image: EImg.RABBIT },
  { word: "kano", image: EImg.CANOE },
  { word: "klo", image: EImg.CLAW },
  { word: "kne", image: EImg.KNEE },
  { word: "kniv", image: EImg.KNIFE },
  { word: "koke", image: EImg.BOIL },
  { word: "kor", image: EImg.QUIRE },
  { word: "kost", image: EImg.BROOM },
  { word: "krok", image: EImg.PEG },
  { word: "krone", image: EImg.CROWN },
  { word: "kråke", image: EImg.CROW },
  { word: "ku", image: EImg.COW },
  { word: "kø", image: EImg.QUEUE },
  // { word: "køye", image: EImg.BUNK_BED, tags: [ETag.DIPHTHONG] },
  { word: "kål", image: EImg.CABBAGE },
  { word: "kåpe", image: EImg.COAT },

  { word: "laks", image: EImg.SALMON },
  { word: "lam", image: EImg.SHEEP },
  { word: "lama", image: EImg.LLAMA },
  { word: "laser", image: EImg.LAZER },
  { word: "lege", image: EImg.DOCTOR },
  { word: "lego", image: EImg.LEGO },
  { word: "ler", image: EImg.LAUGH },
  { word: "leie", image: EImg.HOLD_HANDS },
  // { word: "reir", image: EImg.NEST, tags: [ETag.DIPHTHONG] },
  { word: "lese", image: EImg.READ },
  { word: "li", image: EImg.HILL },
  { word: "lim", image: EImg.GLUE },
  { word: "lo", image: EImg.LAUGH_2 }, // TODO: have discuss this word (because is past tense).
  { word: "los", image: EImg.SEA_PILOT },
  { word: "lue", image: EImg.HAT_2 },
  { word: "lus", image: EImg.LICE },
  { word: "lyd", image: EImg.SOUND },
  { word: "lyn", image: EImg.LIGHTNING },
  { word: "lys", image: EImg.LIGHTBULB },
  { word: "lyse", image: EImg.LIGHT },
  { word: "lår", image: EImg.THIGH },
  { word: "lås", image: EImg.PADLOCK },
  { word: "låve", image: EImg.BARN },
  { word: "løk", image: EImg.ONION },
  { word: "løpe", image: EImg.RUN },
  // { word: "løype", image: EImg.SKI_TRACKS, tags: [ETag.DIPHTHONG] },
  { word: "løve", image: EImg.LION },

  { word: "mage", image: EImg.STOMACH },
  { word: "male", image: EImg.PAINT },
  { word: "mars", image: EImg.MARS },
  { word: "mat", image: EImg.FOOD },
  // { word: "maur", image: EImg.ANT, tags: [ETag.DIPHTHONG] },
  { word: "mel", image: EImg.FLOUR },
  { word: "melk", image: EImg.MILK },
  { word: "melon", image: EImg.WATERMELON },
  // { word: "mjau", image: EImg.MEOW, tags: [ETag.DIPHTHONG] },
  { word: "mobil", image: EImg.CELLPHONE },
  { word: "molo", image: EImg.BREAKWATER },
  { word: "moped", image: EImg.MOPED },
  { word: "mor", image: EImg.MOTHER },
  { word: "mos", image: EImg.MASHED_POTATOES },
  { word: "mule", image: EImg.MULE },
  { word: "mur", image: EImg.BRICK_WALL },
  { word: "mus", image: EImg.MOUSE },
  { word: "måke", image: EImg.SEAGULL },
  { word: "mål", image: EImg.GOAL },
  { word: "måne", image: EImg.MOON },

  { word: "nal", image: EImg.CLEANING, tags: [ETag.MISSING_SOUND_FILE] }, // is this even a Norwegian word?
  // { word: "neie", tags: [ETag.DIPHTHONG] },
  { word: "neve", image: EImg.FIST },
  { word: "ni", image: EImg.NINE },
  { word: "nyse", image: EImg.SNEEZE },
  { word: "nål", image: EImg.NEEDLE },

  { word: "okse", image: EImg.BULL },
  { word: "Ole", image: EImg.NAME_OLE, tags: [ETag.PERSON_NAME] },
  { word: "os", image: EImg.STEAM },
  { word: "ost", image: EImg.CHEESE },
  { word: "oter", image: EImg.OTTER },

  // { word: "pai", image: EImg.PIE, tags: [ETag.DIPHTHONG] },
  { word: "pedal", image: EImg.PEDAL },
  // { word: "peis", image: EImg.FIREPLACE, tags: [ETag.DIPHTHONG] },
  { word: "Per", image: EImg.NAME_PER, tags: [ETag.PERSON_NAME] },
  { word: "piano", image: EImg.PIANO },
  { word: "pil", image: EImg.ARROW },
  { word: "pipe", image: EImg.PIPE },
  { word: "pirat", image: EImg.PIRATE_1 },
  // { word: "pleie", image: EImg.CARE_FOR, tags: [ETag.DIPHTHONG] },
  // { word: "pløye", image: EImg.PLOUGH, tags: [ETag.DIPHTHONG] },
  { word: "pokal", image: EImg.TROPHY },
  { word: "pose", image: EImg.BAG },
  { word: "potet", image: EImg.POTATO },
  { word: "prins", image: EImg.PRINCE },
  { word: "pus", image: EImg.CAT },
  { word: "puma", image: EImg.PUMA },
  { word: "pute", image: EImg.PILLOW },
  { word: "pære", image: EImg.PEAR },
  { word: "pølse", image: EImg.SAUSAUGE },

  { word: "radio", image: EImg.RADIO },
  // { word: "raute", image: EImg.COW_SOUND, tags: [ETag.DIPHTHONG] },
  { word: "reke", image: EImg.SCHRIMP }, // TODO: fix spell error (should be shrimp)
  // { word: "rein", image: EImg.REINDEER, tags: [ETag.DIPHTHONG] },
  { word: "rev", image: EImg.FOX },
  // { word: "reir", image: EImg.NEST, tags: [ETag.DIPHTHONG] },
  { word: "ri", image: EImg.RIDE },
  { word: "rik", image: EImg.RICH },
  { word: "rim", image: EImg.FROST },
  { word: "ris", image: EImg.RICE },
  { word: "rive", image: EImg.TEARING },
  { word: "ro", image: EImg.PADDLE },
  { word: "rom", image: EImg.ROOM },
  { word: "rope", image: EImg.SHOUT_1 },
  { word: "rosa", image: EImg.PINK },
  { word: "rose", image: EImg.ROSE },
  { word: "røver", image: EImg.PIRATE_2, tags: [ETag.MISSING_SOUND_FILE] },
  // { word: "røyk", image: EImg.SMOKE, tags: [ETag.DIPHTHONG] },
  // { word: "røys", image: EImg.PILE_OF_ROCKS, tags: [ETag.DIPHTHONG] },

  { word: "sag", image: EImg.SAW },
  { word: "sage", image: EImg.SAWING },
  { word: "saks", image: EImg.SCISSORS },
  { word: "sal", image: EImg.SADDLE },
  // { word: "sau", image: EImg.SHEEP, tags: [ETag.DIPHTHONG] },
  // { word: "saus", image: EImg.SAUCE, tags: [ETag.DIPHTHONG] },
  { word: "seks", image: EImg.SIX },
  { word: "sel", image: EImg.SEAL },
  { word: "sele", image: EImg.BUCKLE },
  // { word: "seile", image: EImg.SAIL, tags: [ETag.DIPHTHONG] },
  { word: "sil", image: EImg.STRAINER },
  { word: "silo", image: EImg.SILO },
  { word: "Siri", image: EImg.NAME_SIRI, tags: [ETag.PERSON_NAME] },
  { word: "skap", image: EImg.CABINET },
  { word: "sko", image: EImg.SHOE },
  { word: "skrue", image: EImg.SCREW },
  { word: "smile", image: EImg.SMILE },
  { word: "snø", image: EImg.SNOWING },
  { word: "sofa", image: EImg.SOFA },
  { word: "sol", image: EImg.SUN },
  { word: "solo", image: EImg.SODA },
  { word: "sope", image: EImg.SWEEP },
  // { word: "speil", image: EImg.MIRROR, tags: [ETag.DIPHTHONG] },
  // { word: "stein", image: EImg.STONE, tags: [ETag.DIPHTHONG] },
  { word: "stige", image: EImg.LADDER },
  { word: "stol", image: EImg.CHAIR },
  // { word: "støy", image: EImg.NOISE, tags: [ETag.DIPHTHONG] },
  { word: "sur", image: EImg.SOUR },
  { word: "sy", image: EImg.SEW },
  { word: "så", image: EImg.SOWING },
  { word: "såpe", image: EImg.SOAP },
  { word: "sår", image: EImg.WOUND },

  { word: "tak", image: EImg.ROOF },
  // { word: "tau", image: EImg.ROPE, tags: [ETag.DIPHTHONG] },
  { word: "telt", image: EImg.TENT },
  { word: "ti", image: EImg.TEN },
  { word: "tiger", image: EImg.TIGER },
  { word: "tine" },
  { word: "to", image: EImg.TWO },
  { word: "tomat", image: EImg.TOMATO },
  { word: "tre", image: EImg.THREE },
  { word: "tute", image: EImg.HONK },
  { word: "tåre", image: EImg.TEAR },
  { word: "tær", image: EImg.TOES },
  // { word: "tøy", image: EImg.CLOTHES, tags: [ETag.DIPHTHONG] },
  // { word: "tøyse", image: EImg.SILLY, tags: [ETag.DIPHTHONG] },

  { word: "ufo", image: EImg.UFO },
  { word: "ugle", image: EImg.OWL },
  { word: "ulv", image: EImg.WOLF },
  { word: "ur", image: EImg.WATCH },

  { word: "vase", image: EImg.VASE },
  // { word: "vaie", image: EImg.FLAG, tags: [ETag.DIPHTHONG] },
  { word: "vekt", image: EImg.SCALE },
  { word: "veske", image: EImg.PURSE },
  { word: "vev", image: EImg.WEAVE },
  { word: "våt", image: EImg.WET },

  { word: "yr", image: EImg.RAIN },
  { word: "yste", image: EImg.CURDING },

  { word: "ål", image: EImg.EEL },
  { word: "åme", image: EImg.CATERPILLAR },
  { word: "åre", image: EImg.PADDLE },
  { word: "ås", image: EImg.HILL },

  { word: "ære", image: EImg.PROUD },

  { word: "øks", image: EImg.AXE },
  { word: "ørn", image: EImg.EAGLE },
  { word: "øve", image: EImg.WORKING_OUT }
  // { word: "øy", image: EImg.ISLAND, tags: [ETag.DIPHTHONG] }
];

/* ========================================= */

export const task_words_nb_NO = [
  ...sharedNorwegian,
  { word: "bro", image: EImg.BRIDGE, tags: [ETag.MISSING_SOUND_FILE] },

  { word: "en", image: EImg.ONE },

  { word: "flue", image: EImg.FLY },
  { word: "føner", image: EImg.HAIR_DRYER },

  { word: "gave", image: EImg.PRESENT },
  { word: "grøt", image: EImg.PORRIDGE },
  { word: "gutt", image: EImg.BOY },

  { word: "hånd", image: EImg.HAND },
  { word: "hilse", image: EImg.HANDSHAKE },
  { word: "hode", image: EImg.HEAD },
  { word: "hånd", image: EImg.HAND },
  { word: "høre", image: EImg.HEAR },
  // { word: "høyde", image: EImg.HIGH_JUMP, tags: [ETag.DIPHTHONG] },

  { word: "lete", image: EImg.HIDE_AND_SEEK, tags: [ETag.MISSING_SOUND_FILE] },
  { word: "lærer", image: EImg.TEACHER },

  { word: "nese", image: EImg.NOSE },

  { word: "peke", image: EImg.POINTING },

  { word: "rør", image: EImg.WATERPIPE },
  // { word: "røyk", image: EImg.SMOKE, tags: [ETag.DIPHTHONG] },

  { word: "se", image: EImg.WATCHING },
  { word: "si", image: EImg.SAY },
  { word: "syv", image: EImg.SEVEN },

  { word: "tyv", image: EImg.THIEF },

  // { word: "vei", image: EImg.ROAD, tags: [ETag.DIPHTHONG] },
  { word: "vindu", image: EImg.WINDOW },

  { word: "øre", image: EImg.EAR },
  { word: "øse", image: EImg.LADLE }

  // { word: "øyne", image: EImg.EYES, tags: [ETag.DIPHTHONG] }
];
