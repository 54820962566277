import { arrayOf } from "../array";

/* ============= */
/* Shameless copy-paste TS PR: */
/* https://github.com/Microsoft/TypeScript/issues/26223 */
type _TupleOf<T, N extends number, R extends unknown[]> = R["length"] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>;

export type TTuple<T, N extends number> = N extends N
  ? number extends N
    ? T[]
    : _TupleOf<T, N, []>
  : never;
/* ============= */

type TTupleOf = <T, N extends number>(val: T, n: N) => TTuple<T, N>;
export const tupleOf: TTupleOf = (val, n) => {
  return arrayOf(val, n) as TTuple<typeof val, typeof n>;
};

/* Mutates tuple... */
/* Could probably do this with some Ramda functions. */
export const sortTuples = (
  t: [string, any][],
  order: "asc" | "desc" = "asc"
) => {
  if (order === "asc") {
    return t.sort((a, b) => (a[0] > b[0] ? 1 : -1));
  }
  return t.sort((a, b) => (a[0] < b[0] ? 1 : -1));
};
