import { defaultLocale as valDefaultLocale, ELocale } from "@imaldev/imal-factory/i18n";
import { createContext, ReactNode, useContext, useState } from "react";

/* Locale tags follow the standard IETF BCP 47, but dashes are replaced by */
/* underscores, as dashes cannot be used in ECMAScript variable names. */
/* tl;dr: [language]_[region] */

/* Do we really need one locale to be non-optional? Think so.. */

/* Check if there is a way toooo... say that must have at least one pair. */

/* Locale tags follow the standard IETF BCP 47, but dashes are replaced by underscores */
/* as dashes cannot be used in JS variable names. */
/* tldr; [language]_[region] */

/* TODO: call this file LocaleContext? */
/* But is only used in de_DE app? */

export const LocaleContext = createContext<{
  locale: ELocale;
  setLocale: (locale: ELocale) => void;
}>(null!);

/* TODO: use these. */
/* no+sv also has a fn which does this? It is located in regex module. */
const getLocaleByDomainExtension = (urlExtention: string, fallback = valDefaultLocale) => {
  const localeByExt: Record<string, ELocale> = {
    de: ELocale.de_DE,
    com: ELocale.en_US,
    es: ELocale.es_ES
  };

  return localeByExt[urlExtention] ?? valDefaultLocale;
};

/* What about domains with multi part extentions? Eg. .co.uk */
/* Maybe check length of second last url part. if > 2 (or what ever is the longest part for this position), */
/*  */
const getUrlExtention: () => string | undefined = () => {
  return window.location.hostname.split(".")[-1] ?? "de";
};

type Props = {
  children: ReactNode;
  defaultLocale?: ELocale;
};

export const LocaleProvider = ({ children, defaultLocale = valDefaultLocale }: Props) => {
  const [locale, setLocale] = useState<ELocale>(
    getLocaleByDomainExtension(getUrlExtention() ?? "de")
  );

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
