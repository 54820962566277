import { clone, concat, range, take } from "ramda";

/* Can never remember javascript array syntax lmao */
/* TODO?: refactor to take countCopied as first arg? More closely follows */
/* the functional paradigm. */
export const arrayOf = <T>(e: T, countCopies = 1) => {
  return range(0, countCopies).map(() => clone(e));
};

/* Doesn't Ramda have a fn like this? */
type TDropIndex = <T>(a: Array<T>, i: number) => T[];
export const dropIndex: TDropIndex = (a, index) => {
  if (index >= a.length) return a;

  return [...a.slice(0, index), ...a.slice(index + 1)];
};

/* TODO: should probably test... */
/* TODO?: allow a to be another type than string. */
export const ensureSize: (size: number, a: string[]) => string[] = (
  size,
  a
) => {
  return a.length >= size
    ? take(size, a)
    : concat(a, new Array(size - a.length).fill(""));
};

/* Credit: @ashleedawg */
/* https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array */
/* _.shuffle is problematic when used with ramda, so use this instead. */
export const shuffle = <T>(list: T[]) => {
  const shuffled = clone(list);
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

/* Could improve(?) by returning tuple `[randElem, remaining]`. */
/* But this will likely destroy performance? */
export const takeRandom = <T>(a: T[]) => {
  return a[Math.floor(Math.random() * a.length)];
};

/* TODO?: add arg to say if should be able to get same element multiple times. */
/* What is the most common use case. */
export const takeRandoms = <T>(n: number, a: T[], removeChosen = false) => {
  return take(Math.min(n, a.length), shuffle(a));
};
