import { Merge } from "../../ts";

export type TWord = string;

/* ===== Tag descriptions: ===== */
// MISSING_SOUND_FILE: a task in "student-app" must only use words with a
// corresponding audio track.
// PERSON_NAME: words which are person names should be omitted from some tasks.
// DIPHTONG: words with diphtongs should be omitted from some tasks.
export enum ETag {
  MISSING_SOUND_FILE = "missing_sound_file", // erfhelkjrhf
  PERSON_NAME = "person_name",
  DIPHTHONG = "diphtong"
}

export type TTaskWord = {
  image?: EImg;
  tags?: ETag[];
  word: TWord;
};

export type ImgWord = Merge<TTaskWord, { image: EImg }>;

/* TODO: verbs should be named in present progressive? eg. "sing" -> "singing". */

/* Filenames for task word images. */
export enum EImg {
  ACE = "ace",
  AEROPLANE = "aeroplane",
  AGONY = "agony",
  ANGEL = "angel",
  ANISE = "anise",
  ANTLERS = "antlers",
  ANT = "ant",
  APPLE_CORE = "apple_core",
  APPLE = "apple",
  APPLES = "apples",
  ARM = "arm",
  AROMA = "aroma",
  ARROW = "arrow",
  AULA = "aula",
  AUTUMN = "autumn",
  AVALANCHE = "avalance" /* TODO: correct spelling of image name. */,
  AXE = "axe",

  BABY_HAT = "baby_hat",
  BABY = "baby",
  BACKPACK = "backpack",
  BACK = "back",
  BAG = "bag",
  BAIT = "bait",
  BAKE = "bake",
  BALL_2 = "ball_2",
  BALLOON = "balloon",
  BALL = "ball",
  BANANA = "banana",
  BAPTISM = "baptism",
  BARK_DOG_1 = "bark_dog_1",
  BARK_DOG_2 = "bark_dog_2",
  BARN = "barn",
  BASKET = "basket",
  BATHE = "bathe",
  BATHROOM = "bathroom",
  BAVERIAN = "baverian",
  BEAK = "beak",
  BEARD = "beard",
  BEAR_FEMALE = "bear_female",
  BEAR = "bear",
  BEE = "bee",
  BEETLE = "beetle",
  BELLY_BUTTON = "belly_button",
  BEND = "bend",
  BEVER = "bever", // TODO: correct spelling: beaver.
  BICYCLE = "bicycle",
  BINOCULARS = "binoculars",
  BITE = "bite",
  BLACKBOARD = "blackboard",
  BLUE = "blue",
  BOAT = "boat",
  BOIL = "boil",
  BONE = "bone",
  BOOK_KIDS = "book_kids",
  BOOK = "book",
  BOSS = "boss",
  BOWLING_PIN = "bowling_pin",
  BOW = "bow",
  BOX = "box",
  BOY = "boy",
  BRAIN = "brain",
  BREAKWATER = "breakwater",
  BRICK_WALL = "brick_wall",
  BRIDGE = "bridge",
  BROOM = "broom",
  BRUSHING = "brushing",
  BUCKET = "bucket",
  BUCKLE = "buckle",
  BUILD = "build",
  BULL = "bull",
  BUMP_INJURY = "bump_injury",
  BUNK_BED = "bunk_bed",
  BUS = "bus",
  BUTTON = "button",

  CABBAGE = "cabbage",
  CABINET = "cabinet",
  CAGE = "cage",
  CAKE = "cake",
  CALENDAR = "calendar",
  CALENDAR_WEEK = "calendar_week",
  CAMEL = "camel",
  CAMPFIRE = "campfire",
  CAMP = "camp",
  CANOE = "canoe",
  CAPE = "cape",
  CAP = "cap",
  CAPTAIN = "captain",
  CAR_CONVERTIBLE = "car_convertible",
  CARDS = "cards",
  CARE_FOR = "care_for",
  CAR = "car",
  CAR_SEAT = "car_seat",
  CART = "cart",
  CASKET = "casket",
  CASTLE = "castle",
  CAT_2 = "cat_2",
  CATCH = "catch",
  CATERPILLAR = "caterpillar",
  CAT = "cat",
  CELEBRATE = "celebrate",
  CELLPHONE = "cellphone",
  CHAIN_2 = "chain_2",
  CHAIN = "chain",
  CHAIR = "chair",
  CHASING = "chasing",
  CHEEK = "cheek",
  CHEESE = "cheese",
  CHERRY = "cherry",
  CHESS = "chess",
  CHICK = "chick",
  CHIMNEY = "chimney",
  CHIMNEY_SWEEP = "chimney_sweep",
  CHOCOLATE_MILK = "chocolate_milk",
  CHOCOLATE = "chocolate",
  CHRISTMAS = "christmas",
  CHURCH = "church",
  CIDER = "cider",
  CIGAR = "cigar",
  CINEMA = "cinema",
  CITY = "city",
  CLAP = "clap",
  CLAW = "claw",
  CLEANING = "cleaning",
  CLOTHES = "clothes",
  CLOUD = "cloud",
  CLOWN = "clown",
  CLUB_WEAPON = "club_weapon",
  COAST = "coast",
  COAT = "coat",
  COFFEE = "coffee",
  COIN = "coin",
  COMBING = "combing",
  COMB = "comb",
  COMIC = "comic",
  CONE = "cone",
  COOKIES = "cookies",
  COOK = "cook",
  CORNER = "corner",
  CORN = "corn",
  COUPLE = "couple",
  COW = "cow",
  COW_SOUND = "cow_sound",
  COWS = "cows",
  CRAB = "crab",
  CRACKER_FIREWORK = "cracker_firework",
  CROWING = "crowing",
  CROWN = "crown",
  CROW = "crow",
  CRY = "cry",
  CUBOID = "cuboid",
  CURDING = "curding",
  CURD = "curd",
  CURTSEY = "curtsey",
  CUTTING = "cutting",

  DAM = "dam",
  DEER = "deer",
  DIVE = "dive",
  DOCK_2 = "dock_2",
  DOCK = "dock",
  DOCTOR = "doctor",
  DOG = "dog",
  DOLL = "doll",
  DOLPHIN = "dolphin",
  DONKEY = "donkey",
  DOOR = "door",
  DOUGH = "dough",
  DOVE = "dove",
  DOZE = "doze",
  DRAGON = "dragon",
  DRAWER = "drawer",
  DRESS = "dress",
  DRINK = "drink",
  DRIVE = "drive",
  DRIVER = "driver",
  DRUM = "drum",
  DUCK = "duck",

  EAGLE = "eagle",
  EAR = "ear",
  EAT = "eat",
  EBEN = "eben",
  EEL = "eel",
  EGG = "egg",
  ELEPHANT = "elephant",
  ELEVATOR = "elevator",
  ELEVEN = "eleven",
  EMBARRASSED = "embarrassed",
  EMPEROR = "emperor",
  EVIL = "evil",
  EXIT = "exit",
  EYE = "eye",
  EYES = "eyes",

  FACTORY = "factory",
  FAIRY = "fairy",
  FART = "fart",
  FATHER = "father",
  FEATHER = "feather",
  FENCE = "fence",
  FEVER = "fever",
  FIELDS = "fields",
  FILE = "file",
  FILM = "film",
  FINGER = "finger",
  FIREPLACE = "fireplace",
  FISHING_NET = "fishing_net",
  FISHING = "fishing",
  FISH = "fish",
  FIST = "fist",
  FIVE = "five",
  FLAG = "flag",
  FLOUR = "flour",
  FLUTE = "flute",
  FLYING = "flying",
  FLY = "fly",
  FOAL_1 = "foal_1",
  FOAL_2 = "foal_2",
  FOOD = "food",
  FOOT = "foot",
  FORK = "fork",
  FOUR = "four",
  FOX = "fox",
  FRAME = "frame",
  FREEZE = "freeze",
  FRIDGE = "fridge",
  FROST = "frost",
  FUN = "fun",

  GAMING = "gaming",
  GAPE = "gape",
  GARDEN = "garden",
  GEESE = "geese",
  GIRL = "girl",
  GLAS = "glas",
  GLASSES = "glasses",
  GLUE = "glue",
  GOAL = "goal",
  GOAT = "goat",
  GOLD = "gold",
  GOLF = "golf",
  GOOSE = "goose",
  GRAPES = "grapes",
  GRAS = "gras",
  GREEN = "green",
  GRILL = "grill",
  GRIMACE_1 = "grimace_1",
  GRIMANCE_2 = "grimance_2",
  GUITAR = "guitar",

  HAIR_DRYER = "hair_dryer",
  HAIR = "hair",
  HAIR_VAX = "hair_vax",
  HAMMER = "hammer",
  HAM = "ham",
  HAND_NET = "hand_net",
  HAND = "hand",
  HANDSHAKE = "handshake",
  HARE = "hare",
  HAT_1 = "hat_1",
  HAT_2 = "hat_2",
  HAWK = "hawk",
  HAY = "hay",
  HEAD = "head",
  HEAR = "hear", // TODO: rename to listen?
  HEART = "heart",
  HELMET = "helmet",
  HELP = "help",
  HEN = "hen",
  HIBERNATING = "hibernating",
  HIDE_AND_SEEK = "hide_and_seek", // TODO: order new image?
  HIGH_JUMP = "high_jump",
  HILL = "hill",
  HI = "hi",
  HISSING = "hissing",
  HITCHHIKING = "hitchhiking",
  HOLD_HANDS = "hold_hands",
  HOLD = "hold",
  HOLE_2 = "hole_2",
  HOLE = "hole",
  HONK = "honk",
  HOOF = "hoof",
  HORN = "horn",
  HORSE = "horse",
  HOUSE = "house",
  HOWL = "howl",
  HUH = "huh",
  HUNTER = "hunter",

  ICE_CUBE = "ice_cube",
  ICE_SKATE = "ice_skate",
  IDA = "ida",
  IGLOO = "igloo",
  IMAGE_NAMES = "image_names",
  INCLINE = "incline",
  ISLAND = "island",

  JACKET_1 = "jacket_1",
  JACKET_2 = "jacket_2",
  JEWEL = "jewel",
  JOKER = "joker",
  JOY = "joy",
  JUDO = "judo",
  JUGGLE = "juggle",
  JUMPING = "jumping",

  KAYAK = "kayak",
  KEY = "key",
  KILO = "kilo",
  KIOSK = "kiosk",
  KISS = "kiss",
  KITCHEN = "kitchen",
  KNEE = "knee",
  KNIFE = "knife",
  KNITTING = "knitting",

  LADDER = "ladder",
  LADLE = "ladle",
  LAUGH = "laugh",
  LAUGH_2 = "laugh_2",
  LAUNDRY = "laundry",
  LAZER = "lazer",
  LEAVES = "leaves",
  LEGO = "lego",
  LETTER = "letter",
  LICE = "lice",
  LIFTER = "lifter",
  LIGHTBULB = "lightbulb",
  LIGHTHOUSE = "lighthouse",
  LIGHTNING = "lightning",
  LIGHT = "light",
  LIME = "lime",
  LION = "lion",
  LIPGLOSS = "lipgloss",
  LITER = "liter",
  LLAMA = "llama",
  LOOK_IN_MIRROR = "look_in_mirror",
  LUXURY = "luxury",
  LYNX = "lynx",

  MACAW = "macaw",
  MAGPIE = "magpie",
  MARRY = "marry",
  MARS = "mars",
  MASHED_POTATOES = "mashed_potatoes",
  MATRESS = "matress",
  MEAT = "meat",
  MELTING_SNOW = "melting_snow",
  MEOW = "meow",
  MERL = "merl",
  METER = "meter",
  MICE = "mice",
  MILK = "milk",
  MIRROR = "mirror",
  MIST = "mist", // rename to fog?
  MONKEY = "monkey",
  MOON = "moon",
  MOOSE = "moose",
  MOPED = "moped",
  MOSQUITO = "mosquito",
  MOTHER = "mother",
  MOUNTAIN = "mountain",
  MOUNTAIN_TOP = "mountain_top",
  MOUSE = "mouse",
  MUESLI = "muesli",
  MULE = "mule",
  MUSHROOM = "mushroom",
  MUSIC = "music",

  NAIL = "nail",
  NAME_EVA = "name_eva",
  NAME_EVEN = "name_even",
  NAME_HAAKON = "name_haakon",
  NAME_IDA = "name_ida",
  NAME_OLE = "name_ole",
  NAME_PER = "name_per",
  NAME_RITA = "name_rita",
  NAME_SIRI = "name_siri",
  NECKLACE = "necklace",
  NECK = "neck",
  NEEDLE = "needle",
  NEST = "nest",
  NEWSPAPER = "newspaper",
  NIGHT = "night",
  NINE = "nine",
  NOISE = "noise",
  NOSE = "nose",
  NOTE = "note",
  NUDLES = "nudles",
  NUMBERS = "numbers",
  NUT_MECHANICAL = "nut_mechanical",
  NUT = "nut",

  OATS = "oats",
  OCEAN = "ocean",
  OIL = "oil",
  ONE = "one",
  ONION = "onion",
  OTTER = "otter",
  OUCH = "ouch",
  OWL = "owl",

  PADDLE = "paddle",
  PADLOCK = "padlock",
  PAINT = "paint",
  PAINT_PICTURE = "paint_picture",
  PALACE = "palace",
  PALM_TREE = "palm_tree",
  PANTS = "pants",
  PARIS = "paris",
  PARTY = "party",
  PAW = "paw",
  PEAR = "pear",
  PEDAL = "pedal",
  PEG = "peg",
  PEGS = "pegs",
  PENCIL_CASE = "pencil_case",
  PENCIL = "pencil",
  PENNANT = "pennant",
  PEN = "pen",
  PEOPLE = "people",
  PHEASANT = "pheasant",
  PHOTO = "photo",
  PIANO = "piano",
  PICTURES = "pictures",
  PIE = "pie",
  PIG = "pig",
  PIGTAIL_HAIR = "pigtail_hair",
  PIKE = "pike",
  PILE_OF_ROCKS = "pile_of_rocks",
  PILLAR = "pillar",
  PILLOW = "pillow",
  PINCH = "pinch",
  PINK = "pink",
  PIPE = "pipe",
  PIRATE_1 = "pirate_1",
  PIRATE_2 = "pirate_2",
  PIRATE_3 = "pirate_3",
  PLANET_EARTH = "planet_earth",
  PLATE = "plate",
  PLIERS = "pliers",
  PLOUGH = "plough",
  PLUS = "plus",
  POCKET = "pocket",
  POINTING = "pointing",
  POISON = "poison",
  POLICE = "police",
  PORRIDGE = "porridge",
  POT = "pot",
  POTATO = "potato",
  PRAY = "pray",
  PRESENT = "present",
  PRINCE = "prince",
  PROUD = "proud",
  PUMA = "puma",
  PUPIL = "pupil",
  PUPPY = "puppy",
  PURPLE = "purple",
  PURSE = "purse",

  QUARK_GERMAN = "quark_german",
  QUEUE = "queue",
  QUIET = "quiet",
  QUIRE = "quire",

  RABBIT = "rabbit",
  RADIO = "radio",
  RAFT = "raft",
  RAIN = "rain",
  RAKE = "rake",
  RAVEN = "raven",
  READ = "read",
  RED = "red",
  REINDEER = "reindeer",
  RELAXED = "relaxed",
  REPAIR = "repair",
  RESTING = "resting",
  RESULT = "result",
  RICE = "rice",
  RICH = "rich",
  RIDE_BIKE = "ride_bike",
  RIDE = "ride",
  RIFLE = "rifle",
  RIGHT = "right",
  RINGING_BELLS = "ringing_bells",
  RISE = "rise",
  RIVER = "river",
  ROAD = "road",
  ROBBER = "robber",
  ROE_DEER = "roe_deer",
  ROLLING_PIN = "rolling_pin",
  ROOF = "roof",
  ROOM = "room",
  ROOSTER = "rooster",
  ROPE = "rope",
  ROSE = "rose",
  ROWING = "rowing",
  RUN = "run",

  SAAMI = "saami",
  SADDLE = "saddle",
  SAIL = "sail",
  SALMON = "salmon",
  SANDWICH_2 = "sandwich_2",
  SANDWICH = "sandwich",
  SANTA = "santa",
  SAUCE = "sauce",
  SAUSAUGE = "sausauge",
  SAWING = "sawing",
  SAW = "saw",
  SAXOPHONE = "saxophone",
  SAY = "say",
  SCALE = "scale",
  SCARF = "scarf",
  SCHOOL = "school",
  SCHRIMP = "schrimp",
  SCISSORS = "scissors",
  SCOLD = "scold",
  SCOOBA_DIVING = "scooba_diving",
  SCOOP_WATER = "scoop_water",
  SCOUTING_2 = "scouting_2",
  SCOUTING = "scouting",
  SCREEN = "screen",
  SCREW = "screw",
  SCYTHE = "scythe",
  SEAGULL = "seagull",
  SEA_LION = "sea_lion",
  SEAL = "seal",
  SEA_PILOT = "sea_pilot",
  SERUM = "serum",
  SEVEN = "seven",
  SEW = "sew",
  SHADOW = "shadow",
  SHAMPOO = "shampoo",
  SHARK = "shark",
  SHEEP = "sheep",
  SHELL = "shell",
  SHIELD = "shield",
  SHIP = "ship",
  SHIRT = "shirt",
  SHOE = "shoe",
  SHOOT = "shoot",
  SHOUT_1 = "shout_1",
  SHOUT_2 = "shout_2",
  SIGN = "sign",
  SILLY = "silly",
  SILO = "silo",
  SING = "sing",
  SINK = "sink",
  SIX = "six",
  SKELETON = "skeleton",
  SKIRT = "skirt",
  SKIS = "skis",
  SKI_TRACKS = "ski_tracks",
  SLEDGING = "sledging",
  SLED = "sled",
  SLEEVE = "sleeve",
  SLICE_OF_BREAD = "slice_of_bread",
  SLOPE = "slope",
  SMELL = "smell",
  SMILE = "smile",
  SMOKE = "smoke",
  SNEEZE = "sneeze",
  SNOWING = "snowing",
  SOAP = "soap",
  SOCKS = "socks",
  SODA = "soda",
  SOFA = "sofa",
  SOUND = "sound",
  SOUR_CREAM = "sour_cream",
  SOUR = "sour",
  SOWING = "sowing",
  SPADE = "spade",
  SPEAK = "speak",
  SPLIT = "split",
  SPOON = "spoon",
  SQUIRREL = "squirrel",
  STABLE = "stable",
  STAIRS = "stairs",
  STAR = "star",
  STEAM = "steam",
  STOMACH = "stomach",
  STONE = "stone",
  STRAINER = "strainer",
  STRAWBERRY = "strawberry",
  STREET = "street",
  STRONG = "strong",
  SUBMARINE = "submarine",
  SUCKLING = "suckling",
  SUITCASE = "suitcase",
  SUN = "sun",
  SWEEP = "sweep",
  SWEETS = "sweets",
  SWIMMING = "swimming",
  SWIMMING_POOL = "swimming_pool",
  SWORD = "sword",
  SYRUP = "syrup",

  TABLE_CLOTH = "table_cloth",
  TABLE = "table",
  TAIL = "tail",
  TALL = "tall",
  TAXI = "taxi",
  TEACHER = "teacher",
  TEARING = "tearing",
  TEAR = "tear",
  TEDDY_BEAR = "teddy_bear",
  TEN = "ten",
  TENT = "tent",
  THANKS = "thanks",
  THIEF = "thief",
  THIGH = "thigh",
  THREE = "three",
  TIGER = "tiger",
  TOAD = "toad",
  TOES = "toes",
  TOILET = "toilet",
  TOMATO = "tomato",
  TOOTH = "tooth",
  TORTOISE = "tortoise",
  TRACKS = "tracks",
  TRAFFIC_LIGHT = "traffic_light",
  TRAIN_2 = "train_2",
  TRAIN = "train",
  TRAM = "tram",
  TREASURE = "treasure",
  TREES = "trees",
  TROLL = "troll",
  TROPHY = "trophy",
  TUBA = "tuba",
  TUB = "tub",
  TUNNEL = "tunnel",
  TWO = "two",
  TYING = "tying",

  UFO = "ufo",
  UNLUCKY = "unlucky",
  UP = "up",

  VASE = "vase",
  VAULT = "vault",
  VIOLIN = "violin",

  WAIT = "wait",
  WALK = "walk",
  WATCHING = "watching",
  WATCH = "watch",
  WATERFALL = "waterfall",
  WATERMELON = "watermelon",
  WATERPIPE = "waterpipe",
  WAVE = "wave",
  WEAVE = "weave",
  WET = "wet",
  WHALE = "whale",
  WHEAT = "wheat",
  WHEEL = "wheel",
  WHISK = "whisk",
  WHISPER = "whisper",
  WHITE = "white",
  WINDOW = "window",
  WIN = "win",
  WITCH = "witch",
  WOLF = "wolf",
  WORKING_OUT = "working_out",
  WOUND = "wound",
  WRITE = "write",

  YAWN = "yawn",
  YELLOW = "yellow", // TODO: ugly image; order new one
  YOGA = "yoga",
  YOYO = "yoyo",
  ZERO = "zero",
  ZOO = "zoo"
}
