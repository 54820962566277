import { PRecord } from "../ts";

/* But will also have a i18n module in `imal-react-ui`. */

export enum ELocale {
  de_DE = "de_DE",
  en_US = "en_US",
  es_ES = "es_ES",
  nb_NO = "nb_NO",
  nn_NO = "nn_NO",
  sv_SE = "sv_SE"
}

export const defaultLocale = ELocale.de_DE;

type TDefaultLocale = ELocale.de_DE;

export type LocaleRecord<T> = PRecord<Exclude<ELocale, TDefaultLocale>, T> & {
  [key in TDefaultLocale]: T;
};

/* Used for retreiving value from a `LocaleRecord`. Locale records may not have an entry */
/* for every locale. In these situations, getLocaleAsset will try to find the fallback */
/* locale for the given locale. If such a locale does not exist, the value `defaultLocale` */
/* is used as a last resort. */

const fallbackLocalesByLocale: Partial<Record<ELocale, ELocale[]>> = {
  [ELocale.nn_NO]: [ELocale.nb_NO],
  [ELocale.nn_NO]: [ELocale.nb_NO]
};

/* TODO: write some tests for this. */
export const getLocaleAsset = <T extends any>(
  lr: LocaleRecord<T>,
  l: ELocale
  /* should loop until we find a ... locale which has an entry in lr. */
) =>
  lr[l] ??
  lr[fallbackLocalesByLocale[l]?.find((fbl) => lr[fbl]) ?? defaultLocale];

/* Where to put this? in i18n module me thinks. */
export const createLocaleAssetFn = <T>(assetByLocale: LocaleRecord<T>) => {
  /* TODO: use a fn which uses fallback locales assets if provided locale has no asset entry. */
  /* But will we always fallback to the same locale for all kinds of assets? */
  return (l: ELocale) => assetByLocale[l] ?? assetByLocale[defaultLocale];
};
