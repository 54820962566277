import * as R from "ramda";
import { createLocaleAssetFn, ELocale, LocaleRecord } from "../../i18n";
import { getSounds, TSound } from "../sound";
import { alphabet_de_DE, vowels_de_DE } from "./locale/de_DE";
import { latinVowels, TAlphabet, TLetter } from "./shared";

export * from "./shared";

const alphabetByLocale: LocaleRecord<TAlphabet> = {
  [ELocale.de_DE]: alphabet_de_DE
};

export const getAlphabet = createLocaleAssetFn(alphabetByLocale);

const vowelsByLocale: LocaleRecord<TSound[]> = {
  [ELocale.de_DE]: vowels_de_DE,
  [ELocale.en_US]: latinVowels
};

export const getVowels = createLocaleAssetFn(vowelsByLocale);

export const isVowel = (letter: TLetter, locale = ELocale.de_DE) =>
  getVowels(locale).includes(letter);

/* Why?? */
export const extractVowels = (locale: ELocale, sounds: TSound[]) =>
  R.intersection(getVowels(locale), sounds);

/* -------------------------------------------------------------------------- */
/* Figure out where to put. */

/* This is imprecise... gets sounds where not all letters are vowels. */
/* Also doesn't belong in `letter` module. */
export const getConsonants = (locale: ELocale, sounds = getSounds(locale)) =>
  R.difference(
    sounds,
    getSounds(locale).filter((sound) =>
      sound.split("").every((s) => isVowel(s, locale))
    )
  );
