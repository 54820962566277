export type PRecord<T extends string, U extends any> = Partial<Record<T, U>>;

/* Enum module? */

// Enum number values will also become keys,
//  and all those keys start with a number,
//  meaning that all keys that start with a string are keys
export const parseEnumKeys = (rec: Record<string, any> | undefined | null) =>
  Object.keys(rec ?? {}).filter((key) =>
    isNaN(parseInt(key[key[0] === "-" ? 1 : 0], 10))
  );

export const parseEnumValues = (rec: Record<string, any> | undefined | null) =>
  parseEnumKeys(rec).map((key) => (rec as Record<string, any>)[key]);

export const toStringBoolMap = (
  val: string | string[] | null | undefined,
  fn?: (str: string) => string
) => {
  const map: Record<string, boolean> = {};
  (typeof val === "string" ? val.split("") : val)?.forEach((str) => {
    map[fn ? fn(str) : str] = true;
  });
  return map;
}

export const recordSize = (rec: Record<string, any> | null | undefined) =>
  parseEnumKeys(rec).length;
