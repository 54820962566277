import { latinAlphabet } from "../../letter/shared";

export const sounds_es_ES = [
  ...latinAlphabet,
  "ñ",
  "ß"
  // "ng", // include or no?
  /* Are these worth including? */
  // "ui",
  // "ue"
  // What about "rr"? Double-r is important in spanish?
  // Also, "ll".
];

/* Have to do some thing for including "enje" in the alphabet... */
