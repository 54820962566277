import * as R from "ramda";
import { ELocale } from "../../../i18n";
import { applyCap, ECap } from "../../capitalization";
import { TSound } from "../../sound";
import { TTaskWord, TWord } from "../shared";
import { getSoundAtIndex, getSoundsInWord } from ".";

/* These functions are only here to help verify that new word sourcing */
/* implementation is more efficient. */
/* TODO: do some benchmarkig. */

type TGetValidWords = (arg: {
  focused?: TSound;
  focusedPosition?: Position;
  sounds?: TSound[];
  taskWords: TTaskWord[];
  locale: ELocale;
  withImage?: boolean;
}) => TWord[];

/* Rename to getValidTaskWords? */
/* Then... return valid TaskWord[], not Word[]. */
/* TODO: omit "Valid" from fn name? Would be nicer to use valid for fns which wrap this one. */
/* Later, do this with trie. */
/* After getting task words with dynamic imports, should store task words in a context? */
/* TODO: rewrite this with trie in imal-factory. */
/* When new fn is written, compare speed of this fn with old one. */
/* Regardless of result, may have to do some smart things improve */
/* percieved performance. */
const getValidWords: TGetValidWords = (arg) => {
  arg.sounds =
    arg.sounds && arg.focused
      ? R.uniq(R.append(arg.focused, arg.sounds))
      : arg.sounds;

  const taskWords = arg.taskWords.filter((w) => w.word.length < 7);

  let words = (
    arg.withImage ? taskWords.filter((tw) => tw.image) : taskWords
  ).map((tw) => tw.word);

  if (arg.sounds) words = getWordsWithSounds(words, arg.sounds, arg.locale);

  if (arg.focused && arg.focusedPosition)
    words = getWordsWithSoundAtPos({
      locale: arg.locale,
      position: arg.focusedPosition,
      sound: arg.focusedPosition,
      words
    });
  else if (arg.focused)
    words = getWordsContainingSound(words, arg.focused, arg.locale);

  return words;
};

export { getValidWords as getValidWordsOld };

/* TODO: this one gets deprecated with trie fns? */
export const hasOnlyKnownSounds = (
  word: TWord,
  knownSounds: string[],
  locale: ELocale
) => {
  const soundsInWord = R.uniq(getSoundsInWord(locale, R.toLower(word), false));
  return (
    R.uniq(R.intersection(soundsInWord, knownSounds)).length ===
    R.uniq(soundsInWord).length
  );
};

export const hasSound = (word: TWord, sound: TSound, locale: ELocale) =>
  getSoundsInWord(locale, applyCap(word, ECap.NORMAL)).includes(sound);

type TGetWordsWithSoundAtPos = (arg: {
  locale: ELocale;
  position: Position;
  sound: TSound;
  words: TWord[];
}) => TWord[];

/* TODO: Doesn't getValidWords do this already? */
/* If not, maybe make getValidWords wrap this fn. */
export const getWordsWithSoundAtPos: TGetWordsWithSoundAtPos = ({
  locale,
  position,
  sound,
  words
}) => {
  const predicateByPos: Record<Position, (tw: TWord) => boolean> = {
    first: (w) =>
      getSoundAtIndex({ index: 0, locale, word: w, preserveCap: false }) ===
      sound,
    middle: (w) =>
      R.tail(R.dropLast(1, getSoundsInWord(locale, w, false))).includes(sound),
    last: (w) => R.last(getSoundsInWord(locale, w, false)) === sound
  };
  return words.filter(predicateByPos[position]);
};

/* Create a fn hasOnlyKnowSounds(sounds, word)? */
// const hasOnlyKnowSounds = () => {};

/* Words where all of sounds in the word appear in SOUNDS. */
const getWordsWithSounds = (
  words: TWord[],
  sounds: TSound[],
  locale: ELocale
) =>
  words.filter((w) =>
    getSoundsInWord(locale, w, false).every((s) => sounds.includes(s))
  );

const getWordsContainingSound = (
  words: TWord[],
  sound: TSound,
  locale: ELocale
) => words.filter((w) => getSoundsInWord(locale, w).includes(sound));

/* Will be simpler if wrap this fn whereever need to get words, and */
/* potentially call it multiple times if need words of different kinds (?? hmmmm). */
/* Padding handles in callers. */

/* Also consider if words to filter should be passed to this function. */
/* Then won't have to filter for task words which have an image multiple */
/* times (if/when have to call this fn multiple times to get enough */
/* words for a task). */

type Position = "first" | "middle" | "last";

/* TODO: fix mapping sheet, and then have a look at this:  */
/* Perhaps task words should also contain an array of its sounds -> precomutation will */
/* speed things up a lot? */
