import { ELocale, LocaleRecord } from "../../i18n";
import { latinAlphabet } from "../letter/shared";
import { sounds_de_DE } from "./locale/de_DE";
import { sounds_es_ES } from "./locale/es_ES";

// NB: "sound" is actually "Phoneme".
export type TSound = string;

export const getSounds = (l: ELocale) => {
  const soundsByLocale: LocaleRecord<TSound[]> = {
    [ELocale.de_DE]: sounds_de_DE,
    [ELocale.es_ES]: sounds_es_ES
  };

  return soundsByLocale[l] ?? latinAlphabet;
};
