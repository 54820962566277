import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/firestore";

/* TODO: convert to firebase v9. */

/* Switch to prod when have tested image caching. */
const configProd = {
  apiKey: "AIzaSyAuzua6rlL6LSE1_GmTXJABdGZnKAbnpcM",
  authDomain: "imal-german-prod.firebaseapp.com",
  databaseURL:
    "https://imal-german-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "imal-german-prod",
  storageBucket: "imal-german-prod.appspot.com",
  messagingSenderId: "444618169563",
  appId: "1:444618169563:web:ceb10ef92a1b7d7bbb80fe",
  measurementId: "G-MMWFTM1JYX"
};

/* Give firebase app instance a name; otherwise instance will conflict with app's primary */
/* instance if they are also using firebase. */
const imalFirebase = firebase.initializeApp(configProd, "imalFactory");

/* Firebase is now version 9.x.x, which seems to have package structure. */
/* Look into if should switch, or just use old one like in imal-german. */
export const db = imalFirebase.firestore();
