/* TODO: each project can possibly have a factory-bridge(right terminology?) */
/* to adjust certain things, such as DefaultLocale. */
/* How would that work though, as some things in package depend on TDefaultLocale... */

import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale } from "./LocaleContext";

/* Would have to define some wrappers in child dependants? */
const defaultLocale = ELocale.de_DE;
type TDefaultLocale = typeof defaultLocale;

type Translations<T> = {
  [key in Exclude<ELocale, TDefaultLocale>]?: Partial<T>;
} & {
  [key in TDefaultLocale]: T;
};

type UseTxt = <T>(textByLocale: Translations<T>) => { txt: T };

export const useTxt: UseTxt = (translations) => {
  const { locale } = useLocale();

  return {
    txt: {
      ...translations[defaultLocale], // Use default locale as fallback.
      ...translations[locale]
    }
  };
};
