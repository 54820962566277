export type TLetter = string;
export type TAlphabet = TLetter[];

/* Does not include y... add good explanation for it. */
export const latinVowels = ["a", "e", "i", "o", "u"];

/* Move to letter module? */
export const latinAlphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z"
];
