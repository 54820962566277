import { ELocale, LocaleRecord } from "../../../i18n";
import { TTaskWord, TWord } from "../shared";
import { task_words_de_DE } from "./de_DE";
import { task_words_es_ES } from "./es_ES";
import { sharedNorwegian, task_words_nb_NO } from "./nb_NO";
import { task_words_nn_NO } from "./nn_NO";
import { task_words_sv_SE } from "./sv_SV";

/* Should go in no-sv module? */
/* ===== */
/* This seems a bit smelly... if need words in both nb_NO and nn_NO, */
/* import and use sharedNorwegian vector directly, instead of defining this fn? */
/* Also, fn type is not inprecise, will always be Record<TWord, true>. */
export const getSharedNorwegianWordsMap: () => Record<TWord, true | undefined> =
  () => Object.fromEntries(sharedNorwegian.map(({ word }) => [word, true]));

export const getTaskWords = (l: ELocale) => {
  const taskWordsByLocale: LocaleRecord<TTaskWord[]> = {
    de_DE: task_words_de_DE,
    es_ES: task_words_es_ES,
    nb_NO: task_words_nb_NO,
    nn_NO: task_words_nn_NO,
    sv_SE: task_words_sv_SE
  };
  return taskWordsByLocale[l] ?? [];
};
