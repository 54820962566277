import * as R from "ramda";
import { ELocale, LocaleRecord } from "../../../i18n";
import { applyCap, ECap, getCap } from "../../capitalization";
import { getSounds, TSound } from "../../sound";
import { TWord } from "../shared";

/* ==================== start getSoundsInWord ==================== */

/* ----- Missing in german ----- */
/* What am I missing in German which make sense to have here? */

/* Also find their args. */
// this will be the generic "getWords"-fn?
// Implement using trie's stuff.
export const getValidWords = () => {};

// const getPairables = () => {}; // hmmm...
// const hasOnlyVowels = () => {}; // hmmm...
/* ---------------------------- */

/* Refactor name of this. */
const getMustBeFirsts = (l: ELocale) => {
  const mustBeFirstsByLocale: LocaleRecord<TSound[]> = {
    [ELocale.de_DE]: ["st", "sp"]
  };
  return mustBeFirstsByLocale[l] ?? [];
};

/* Get sound at index `i` in string `s`. */
/* Can deprecate this when start using trie stuff? */
export const getSoundAtIndex: (arg: {
  index: number;
  locale: ELocale;
  word: TWord;
  preserveCap?: boolean;
}) => TSound = (arg) => {
  arg.word = arg.word.toLocaleLowerCase();
  if (arg.word.length === 1) return arg.word;
  const mustBeFirsts = getMustBeFirsts(arg.locale);
  const ls = R.difference(getSounds(arg.locale), mustBeFirsts);

  let val;
  const i = arg.index;
  /* Might have to refactor depending on future locales. */
  if (i === 0 && mustBeFirsts.includes(R.slice(0, 2, arg.word)))
    val = R.slice(0, 2, arg.word);
  else if (ls.includes(R.slice(i, i + 3, arg.word)))
    val = R.slice(i, i + 3, arg.word);
  else if (ls.includes(R.slice(i, i + 2, arg.word)))
    val = R.slice(i, i + 2, arg.word);
  else val = arg.word[i];

  return arg.preserveCap ? applyCap(val, getCap(arg.word)) : val;
};

/* Splits a word into its constituent sounds. */
export const getSoundsInWord = (l: ELocale, w: TWord, preserveCap = false) => {
  const letters = w.toLocaleLowerCase().split("");
  let sounds: string[] = [];

  while (sounds.join("").length < letters.length) {
    sounds = R.append(
      getSoundAtIndex({
        locale: l,
        word: w,
        index: sounds.join("").length,
        preserveCap
      }),
      sounds
    );
  }

  if (!preserveCap) return sounds;

  const cap = getCap(w);

  if (cap === ECap.FIRST_UPPER)
    return R.prepend(
      applyCap(R.head(sounds) ?? "", ECap.FIRST_UPPER),
      R.tail(sounds)
    );
  if (cap === ECap.UPPER) return sounds.map((s) => s.toLocaleUpperCase());
  return sounds;
};

/* ==================== end getSoundsInWord ==================== */
