import { TBinaryNumber } from "../../ts";
import { Size } from "../geometry";
import { DrawAction } from "../canvas";

export enum EMappingOptions {
  mode = "mode",
  category = "category",
  is_lower = "is_lower"
}

export enum EMappingMode {
  writing,
  reading
}

export enum EMappingCategory {
  letters,
  words
}

// Important protocol for entry performance:
//  - Worst performance: 0
//  - Best performance: 1
//  - Non-binary (in-between) performance: 2...N
export enum EEntryPerformance {
  fail,
  success,
  medium
}

export type MappingTuple = {
  teacher_id: number;
  student_id: number;
  session_id: string;
  entry_id: string;
  word_id: string | null;
  seq_id: number | null;
  success_seq_id: number | null;
  mode: EMappingMode;
  category: EMappingCategory;
  entry: string;
  performance: EEntryPerformance;
  is_lower: TBinaryNumber;
  word: string | null;
  position: number | null;
  is_non_word: TBinaryNumber | null;
  created_at: Date;
  me_started_at: Date;
  me_finished_at: Date;
};

export type MappingSession = {
  id: string;
  teacher_id?: number;
  student_id: number;
  seq_id: number;
  success_seq_id: number | null;
  [EMappingOptions.mode]: EMappingMode;
  [EMappingOptions.category]: EMappingCategory;
  [EMappingOptions.is_lower]: boolean;
  entries: MappingEntry[];
  words: MappingWord[];
  created_at: string; // UTC
  is_deleted?: boolean;
};

export type MappingEntry = Pick<MappingSession, "id"> & {
  entry: string; // Letter or word
  performance: EEntryPerformance;
  started_at: string; // UTC
  finished_at: string | null; // UTC
};

export type MappingWord = Pick<MappingSession, "id"> & {
  word: string;
  isNonWord: boolean;
  position?: number;
};

export type MappingEntryMetadata = {
  entry_id: MappingEntry["id"] | null; // Weak reference
  canvas: Size;
  stroke_width: number;
  strokes: Array<[number, number, DrawAction.start?]>; // Array<[x, y, isStart]>
  lines: Array<[number, number, number, number]>; // Array<[x, y, width, height]>
}

export type MappingSessionMap = { [session_id: string]: MappingSession };
