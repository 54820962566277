import { TTaskWord, EImg, ETag } from "../shared";

export const task_words_de_DE: TTaskWord[] = [
  { word: "Ada" }, // person name?
  { word: "Adler", image: EImg.EAGLE },
  { word: "Ameise", image: EImg.ANT },
  { word: "Ampel", image: EImg.TRAFFIC_LIGHT },
  { word: "Amsel", image: EImg.MERL },
  { word: "angeln", image: EImg.FISHING },
  { word: "Anis", image: EImg.ANISE },
  { word: "Apfel", image: EImg.APPLE },
  { word: "Ara", image: EImg.MACAW },
  { word: "Arm", image: EImg.ARM },
  { word: "Aroma", image: EImg.AROMA },
  { word: "Arzt", image: EImg.DOCTOR },
  { word: "Atom" },
  { word: "Auge", image: EImg.EYE },
  { word: "Augen", image: EImg.EYES },
  { word: "Aula", image: EImg.AULA },
  { word: "Auto", image: EImg.CAR },
  { word: "Axt", image: EImg.AXE },

  { word: "Baby", image: EImg.BABY },
  { word: "backen", image: EImg.BAKE },
  { word: "Bad", image: EImg.BATHROOM },
  { word: "baden", image: EImg.BATHE },
  { word: "Bank" },
  { word: "Bart", image: EImg.BEARD },
  { word: "bauen", image: EImg.BUILD },
  { word: "Bauer" },
  { word: "Baum" },
  { word: "Bayer", image: EImg.BAVERIAN },
  { word: "bequem", image: EImg.RELAXED },
  { word: "Berg", image: EImg.MOUNTAIN },
  { word: "Besen", image: EImg.BROOM },
  { word: "beten", image: EImg.PRAY },
  { word: "Beule", image: EImg.BUMP_INJURY },
  { word: "Beutel" },
  { word: "Biber", image: EImg.BEVER },
  { word: "biegen", image: EImg.BEND },
  { word: "Biene", image: EImg.BEE },
  { word: "binden", image: EImg.TYING },
  { word: "Birne", image: EImg.PEAR },
  { word: "blau", image: EImg.BLUE },
  { word: "Blitz", image: EImg.LIGHTNING },
  { word: "Boden" },
  { word: "Bogen", image: EImg.BOW },
  { word: "Boje" },
  { word: "Box", image: EImg.BOX },
  { word: "Boxer" },
  { word: "Brei", image: EImg.MASHED_POTATOES },
  { word: "Brief", image: EImg.LETTER },
  { word: "Brücke", image: EImg.BRIDGE },
  { word: "Buch", image: EImg.BOOK },
  { word: "Burg", image: EImg.CASTLE },
  { word: "Bus", image: EImg.BUS },
  { word: "Bär", image: EImg.BEAR },
  { word: "Bären", image: EImg.BEAR_FEMALE },
  { word: "böse", image: EImg.EVIL },
  { word: "Bügel" },

  { word: "Clown", image: EImg.CLOWN },
  /* 6 sounds, but include anyway, as don't have many words with "c". */
  { word: "Cabrio", image: EImg.CAR_CONVERTIBLE },
  { word: "Chef", image: EImg.BOSS },

  { word: "Dach", image: EImg.ROOF },
  //  { image: EImage.image: "???", word: "Dame" }, Need a pic for woman!
  { word: "Dampf", image: EImg.STEAM },
  { word: "danke", image: EImg.THANKS },
  { word: "Datum", image: EImg.CALENDAR },
  { word: "Degen", image: EImg.SWORD },
  { word: "Delfin", image: EImg.DOLPHIN },
  { word: "Dieb", image: EImg.THIEF },
  { word: "Doris" },
  { word: "Dose" },
  { word: "drei", image: EImg.THREE },
  { word: "dösen", image: EImg.DOZE },

  { word: "eben" /* , image: EImage.image: "smooth"*/ },
  { word: "Eber" },
  { word: "Ecke", image: EImg.CORNER },
  { word: "edel" },
  { word: "Ei", image: EImg.EGG },
  { word: "Eile" },
  { word: "Eimer", image: EImg.BUCKET },
  { word: "eins", image: EImg.ONE },
  { word: "Eis", image: EImg.ICE_CUBE },
  { word: "Elch", image: EImg.MOOSE },
  { word: "elf", image: EImg.ELEVEN },
  { word: "Elsa", image: EImg.NAME_EVA, tags: [ETag.PERSON_NAME] },
  { word: "Emil", image: EImg.NAME_EVEN, tags: [ETag.PERSON_NAME] },
  { word: "eng" },
  { word: "Engel", image: EImg.ANGEL },
  { word: "Ente", image: EImg.DUCK },
  { word: "Erde", image: EImg.PLANET_EARTH },
  { word: "Esel", image: EImg.DONKEY },
  { word: "Eule", image: EImg.OWL },
  { word: "Euro" },

  { word: "Faden" },
  { word: "Falke", image: EImg.HAWK },
  { word: "fangen", image: EImg.CATCH },
  { word: "Farbe" },
  { word: "Fasan", image: EImg.PHEASANT },
  { word: "Faust", image: EImg.FIST },
  { word: "Feder", image: EImg.FEATHER },
  { word: "fegen", image: EImg.SWEEP },
  { word: "feiern", image: EImg.CELEBRATE },
  { word: "Feile", image: EImg.FILE },
  //  { image: EImage.image: "grimace_2", word: "feixen" }, /* Why no work?? */
  { word: "Fest" },
  { word: "Feuer", image: EImg.CAMPFIRE },
  { word: "feuern", image: EImg.SHOOT },
  { word: "Fibel", image: EImg.BOOK_KIDS },
  { word: "Fieber", image: EImg.FEVER },
  { word: "Figur" },
  { word: "Film", image: EImg.FILM },
  { word: "Finger", image: EImg.FINGER },
  { word: "Fisch", image: EImg.FISH },
  { word: "fischen" },
  { word: "Fliege", image: EImg.FLY },
  { word: "Floß", image: EImg.RAFT },
  { word: "Flöte", image: EImg.FLUTE },
  { word: "Foto", image: EImg.PHOTO },
  { word: "Fotos", image: EImg.PICTURES },
  { word: "Fratze", image: EImg.GRIMACE_1 },
  { word: "Freude", image: EImg.JOY },
  { word: "Frosch" },
  { word: "Frost" },
  { word: "Fuchs", image: EImg.FOX },
  { word: "Fuß", image: EImg.FOOT }, // where is "ß" in alphabet?
  { word: "Furz", image: EImg.FART },
  { word: "Fön", image: EImg.HAIR_DRYER },
  { word: "fünf", image: EImg.FIVE },

  { word: "Gabel", image: EImg.FORK },
  { word: "Gans", image: EImg.GOOSE },
  { word: "geben" },
  { word: "Geige", image: EImg.VIOLIN },
  { word: "Gel" },
  { word: "Gift", image: EImg.POISON },
  { word: "Gipfel", image: EImg.MOUNTAIN_TOP },
  { word: "Gips", image: EImg.HOLD },
  { word: "Glas", image: EImg.GLAS },
  { word: "Gockel", image: EImg.ROOSTER },
  { word: "Golf", image: EImg.GOLF },
  { word: "Gras", image: EImg.GRAS },
  { word: "groß", image: EImg.TALL },
  { word: "grün", image: EImg.GREEN },
  { word: "grüßen", image: EImg.HANDSHAKE },
  { word: "Gänse", image: EImg.GEESE },

  { word: "Hafer", image: EImg.OATS },
  { word: "Hafen" },
  { word: "Hagel" },
  { word: "Hai", image: EImg.SHARK },
  { word: "Haken", image: EImg.PEG },
  { word: "Hals", image: EImg.NECK },
  { word: "Hand", image: EImg.HAND },
  { word: "Handy", image: EImg.CELLPHONE },
  { word: "Hans", image: EImg.NAME_HAAKON, tags: [ETag.PERSON_NAME] },
  { word: "Harke", image: EImg.RAKE },
  { word: "Hase", image: EImg.HARE },
  { word: "Haube", image: EImg.BABY_HAT },
  { word: "Haus", image: EImg.HOUSE },
  { word: "heben" },
  { word: "Hefe" },
  { word: "Heidi" },
  { word: "Helm", image: EImg.HELMET },
  { word: "Herz", image: EImg.HEART },
  { word: "Heu", image: EImg.HAY },
  { word: "heulen", image: EImg.HOWL },
  { word: "Hexe", image: EImg.WITCH },
  { word: "Hirsch", image: EImg.DEER },
  { word: "hoch", image: EImg.UP },
  { word: "Hof", image: EImg.GARDEN },
  { word: "Horn", image: EImg.HORN },
  { word: "Hose", image: EImg.PANTS },
  { word: "Huf", image: EImg.HOOF },
  { word: "Hund", image: EImg.DOG },
  { word: "Hunger" },
  { word: "hupen", image: EImg.HONK },
  { word: "Hut", image: EImg.HAT_1 },
  { word: "Hände" },
  { word: "Häsin" },
  { word: "Häuser" },
  { word: "hören", image: EImg.HEAR },
  { word: "Hügel", image: EImg.HILL },
  { word: "hüpfen", image: EImg.JUMPING },

  { word: "Ida", image: EImg.IDA },
  { word: "Igel" },
  { word: "Iglu", image: EImg.IGLOO },
  { word: "Insel", image: EImg.ISLAND },

  { word: "Jacke", image: EImg.JACKET_1 },
  { word: "jagen", image: EImg.CHASING },
  { word: "Jojo", image: EImg.YOYO },
  { word: "Joker", image: EImg.JOKER },
  { word: "Judo", image: EImg.JUDO },
  { word: "Job" },
  { word: "Jubel" },
  { word: "Jungs" },
  { word: "jung" },
  { word: "Junge", image: EImg.BOY },
  { word: "Juwel", image: EImg.JEWEL },
  { word: "Jäger", image: EImg.HUNTER },

  /* Sorted-cursor. */
  { word: "Kabel" },
  { word: "Kai", image: EImg.DOCK_2 },
  { word: "Kaiser", image: EImg.EMPEROR },
  { word: "Kajak", image: EImg.KAYAK },
  { word: "Kamel", image: EImg.CAMEL },
  { word: "Kamin", image: EImg.CHIMNEY }, // Alternatively use imageName "fireplace".
  { word: "Kanal" },
  { word: "Kanu", image: EImg.CANOE },
  { word: "Katze", image: EImg.CAT },
  { word: "Kegel", image: EImg.CONE }, // imageName "cone" or "bowling_pin"? I like bowling pin better.
  { word: "Keks", image: EImg.COOKIES },
  { word: "Kessel", image: EImg.POT },
  { word: "Kette", image: EImg.CHAIN_2 },
  { word: "Keule", image: EImg.CLUB_WEAPON },
  { word: "Kiefer" },
  { word: "Kilo", image: EImg.KILO },
  { word: "Kino", image: EImg.CINEMA },
  { word: "Kiosk", image: EImg.KIOSK },
  { word: "Kirche", image: EImg.CHURCH },
  { word: "Kirsche", image: EImg.CHERRY },
  { word: "Kiste", image: EImg.CASKET },
  { word: "Kiwi" },
  { word: "Klaue", image: EImg.CLAW },
  { word: "Klo", image: EImg.TOILET },
  { word: "Kloß" },
  { word: "Knie", image: EImg.KNEE },
  { word: "Knopf", image: EImg.BUTTON },
  { word: "Koch", image: EImg.COOK },
  { word: "kochen", image: EImg.BOIL },
  { word: "Kopf", image: EImg.HEAD },
  { word: "Korb", image: EImg.BASKET },
  { word: "Korn" },
  { word: "Kran" },
  { word: "Kreis" },
  { word: "Kreuz" },
  { word: "Krone", image: EImg.CROWN },
  { word: "Kröte", image: EImg.TOAD },
  { word: "Kuchen", image: EImg.CAKE },
  { word: "Kugel", image: EImg.BALL_2 },
  { word: "Kuli", image: EImg.PEN },
  { word: "Kuss", image: EImg.KISS },
  { word: "Käfer", image: EImg.BEETLE },
  { word: "Käfig", image: EImg.CAGE },
  { word: "Käse", image: EImg.CHEESE },
  { word: "käsen", image: EImg.CURDING },
  { word: "Köder", image: EImg.BAIT },
  { word: "Küche", image: EImg.KITCHEN },
  { word: "Küken", image: EImg.CHICK },
  { word: "Küste", image: EImg.COAST },

  { word: "Lachs", image: EImg.SALMON },
  { word: "Laib" },
  { word: "Laken" },
  { word: "Lama", image: EImg.LLAMA },
  { word: "Lampe" },
  { word: "Larve", image: EImg.CATERPILLAR },
  { word: "Laser", image: EImg.LAZER },
  { word: "Laub", image: EImg.LEAVES },
  { word: "Lego", image: EImg.LEGO },
  { word: "Leim", image: EImg.GLUE },
  { word: "Leine" },
  { word: "Leiter", image: EImg.LADDER },
  { word: "Leo" },
  { word: "Leser" },
  { word: "Leute", image: EImg.PEOPLE },
  { word: "Licht", image: EImg.LIGHTBULB },
  { /* image: EImage.image: "pink" */ word: "lila" },
  { word: "Lili" },
  { word: "Limo", image: EImg.SODA },
  { word: "Lisa" },
  { word: "Liter", image: EImg.LITER },
  { word: "Loch", image: EImg.HOLE },
  { word: "Lotse", image: EImg.SEA_PILOT },
  { word: "Luchs", image: EImg.LYNX },
  { word: "Luke" },
  { word: "Lupe" },
  { word: "Luxus", image: EImg.LUXURY },
  { word: "Lärm", image: EImg.SOUND },
  { word: "Läuse", image: EImg.LICE },
  { word: "läuten", image: EImg.RINGING_BELLS }, // potentially läuten instead?
  { word: "Löwe", image: EImg.LION },
  { word: "Soda" },
  { word: "lachen", image: EImg.LAUGH },
  { word: "lang" },
  { word: "lecker" },
  { word: "legen" },
  { word: "leise", image: EImg.QUIET },
  { word: "lesen", image: EImg.READ },

  { word: "Mais", image: EImg.CORN },
  { word: "malen", image: EImg.PAINT },
  { word: "mama" },
  { word: "Mantel", image: EImg.COAT },
  { word: "Mars", image: EImg.MARS },
  { word: "Mauer", image: EImg.BRICK_WALL },
  { word: "Maul", image: EImg.MULE },
  { word: "Maus", image: EImg.MOUSE },
  { word: "Meise" },
  { word: "Menü" },
  { word: "Meter", image: EImg.METER },
  { word: "Mia", image: EImg.NAME_PER, tags: [ETag.PERSON_NAME] },
  { word: "miau", image: EImg.MEOW },
  { word: "Milch", image: EImg.MILK },
  { word: "Mimi" },
  { word: "Mimik" },
  { word: "mini" },
  { word: "Minus" },
  { word: "Mio" },
  { word: "Mixer" },
  { word: "Mole", image: EImg.BREAKWATER },
  { word: "Mond", image: EImg.MOON },
  { word: "Moped", image: EImg.MOPED },
  { word: "Mops" },
  { word: "Most", image: EImg.CIDER },
  { word: "Motor" },
  { word: "Muli" },
  { word: "Mus" },
  { word: "Muschel", image: EImg.SHELL },
  { word: "Musik", image: EImg.MUSIC },
  { word: "Mut" },
  { word: "Mäuse", image: EImg.MICE },
  { word: "Möbel" },
  { word: "Möwe", image: EImg.SEAGULL },
  { word: "Mücke", image: EImg.MOSQUITO },
  { word: "müde" },
  { word: "Münze", image: EImg.COIN },
  { word: "Müsli", image: EImg.MUESLI },
  { word: "Mütze", image: EImg.HAT_2 },

  { word: "Nabel", image: EImg.BELLY_BUTTON },
  { word: "Nacht", image: EImg.NIGHT },
  { word: "Nacken", image: EImg.NECK },
  { word: "Nadel", image: EImg.NEEDLE },
  { word: "Nagel", image: EImg.NAIL },
  { word: "Nase", image: EImg.NOSE },
  { word: "Natur" },
  { word: "Nebel", image: EImg.MIST },
  { word: "Nest", image: EImg.NEST },
  { word: "Netz", image: EImg.FISHING_NET },
  { word: "Nina" },
  { word: "Nixe" },
  { word: "Note", image: EImg.NOTE },
  { word: "Nudel", image: EImg.NUDLES },
  { word: "nackt" },
  { word: "neun", image: EImg.NINE },
  { word: "niesen", image: EImg.SNEEZE },

  { word: "Ochse", image: EImg.BULL },
  { word: "Ole", image: EImg.NAME_OLE, tags: [ETag.PERSON_NAME] },
  { word: "Oma" },
  { word: "Opa" },
  { word: "Ort" }, // Village

  { word: "Palais", image: EImg.PALACE },
  { word: "Palme", image: EImg.PALM_TREE },
  { word: "Papa" },
  { word: "Paris", image: EImg.PARIS },
  { word: "Park" },
  { word: "Party", image: EImg.PARTY },
  { word: "Pech", image: EImg.UNLUCKY },
  { word: "Pedal", image: EImg.PEDAL },
  { word: "Pelz" },
  { word: "Pfad" },
  { word: "Pferd", image: EImg.HORSE },
  { word: "Pflug", image: EImg.PLOUGH },
  { word: "Pfote", image: EImg.PAW },
  { word: "Piano", image: EImg.PIANO },
  { word: "Pilz", image: EImg.MUSHROOM },
  { word: "Pirat", image: EImg.PIRATE_1 },
  { word: "Pokal", image: EImg.TROPHY },
  { word: "Pony", image: EImg.HORSE },
  { word: "Prinz", image: EImg.PRINCE },
  { word: "Pudel" },
  { word: "Puma", image: EImg.PUMA },
  { word: "Pute" },
  { word: "plus", image: EImg.PLUS },
  { word: "putzen", image: EImg.SWEEP },

  { word: "Quader", image: EImg.CUBOID },
  { word: "Qual", image: EImg.AGONY },
  { word: "Quark", image: EImg.CURD },
  { word: "Quirl", image: EImg.WHISK },

  { word: "Rabe", image: EImg.RAVEN },
  { word: "Rad", image: EImg.WHEEL },
  { word: "Radio", image: EImg.RADIO },
  { word: "Raum", image: EImg.ROOM },
  { word: "Raupe" },
  { word: "Rede" },
  { word: "Regal" },
  { word: "Regen", image: EImg.RAIN },
  { word: "Reis", image: EImg.RICE },
  { word: "Rita", image: EImg.NAME_RITA, tags: [ETag.PERSON_NAME] },
  { word: "Rock", image: EImg.SKIRT },
  { word: "Rom" },
  { word: "Rose", image: EImg.ROSE },
  { word: "Rubin" },
  { word: "Rudel" },
  { word: "Ruder", image: EImg.PADDLE },
  { word: "Ruf", image: EImg.SHOUT_1 },
  { word: "Rute" },
  { word: "Räuber", image: EImg.ROBBER },
  { word: "Römer" },
  { word: "Röte" },
  { word: "Rübe" },
  { word: "Rücken", image: EImg.BACK },
  { word: "rasen" },
  { word: "reden", image: EImg.SPEAK },
  { word: "reisen" },
  { word: "reiten", image: EImg.RIDE },
  { word: "reißen", image: EImg.TEARING },
  { word: "rosa", image: EImg.PINK },
  { word: "rot", image: EImg.RED },
  { word: "rufen", image: EImg.SHOUT_1 },

  { word: "Sack" },
  { word: "Saft" },
  { word: "Salat" },
  { word: "Samen" },
  { word: "Sau", image: EImg.PIG },
  { word: "Schaf", image: EImg.SHEEP },
  { word: "Schal", image: EImg.SCARF },
  { word: "Schere", image: EImg.SCISSORS },
  { word: "Schirm", image: EImg.SCREEN },
  { word: "Schlag" },
  { word: "Schrank", image: EImg.CABINET },
  { word: "Schule", image: EImg.SCHOOL },
  { word: "Schwanz", image: EImg.TAIL },
  { word: "Schäfer" },
  { word: "Schüler", image: EImg.PUPIL },
  { word: "Segel" },
  { word: "Seife", image: EImg.SOAP },
  { word: "Seil", image: EImg.ROPE },
  { word: "Sense", image: EImg.SCYTHE },
  { word: "Serum", image: EImg.SERUM },
  { word: "Sieb", image: EImg.STRAINER },
  { word: "Silo", image: EImg.SILO },
  { word: "Siri", image: EImg.NAME_SIRI, tags: [ETag.PERSON_NAME] },
  { word: "Sirup", image: EImg.SYRUP },
  { word: "Sitz", image: EImg.CAR_SEAT },
  { word: "Ski", image: EImg.SKIS },
  { word: "Socken", image: EImg.SOCKS },
  { word: "Sofa", image: EImg.SOFA },
  { word: "Soße", image: EImg.SAUCE },
  { word: "Spagat", image: EImg.SPLIT },
  { word: "Span" },
  { word: "Spaten", image: EImg.SPADE },
  { word: "Spaß", image: EImg.FUN },
  { word: "Spinat" },
  { word: "Sport" },
  { word: "Sprit" },
  { word: "Spur", image: EImg.SKI_TRACKS },
  { word: "Spuren", image: EImg.TRACKS },
  { word: "Spüle", image: EImg.SINK },
  { word: "Spülen" },
  { word: "Start" },
  { word: "Stefan" },
  { word: "Stein", image: EImg.STONE },
  { word: "Stern", image: EImg.STAR },
  { word: "Stift", image: EImg.PENCIL },
  { word: "Sturm" },
  { word: "Susi" },
  { word: "Säbel" },
  { word: "Säge", image: EImg.SAW },
  { word: "Säule", image: EImg.PILLAR },
  { word: "sagen", image: EImg.SAY },
  { word: "sauer", image: EImg.SOUR },
  { word: "sechs", image: EImg.SIX },
  { word: "sieben", image: EImg.SEVEN },
  { word: "siegen", image: EImg.WIN },
  { word: "singen", image: EImg.SING },
  { word: "stark", image: EImg.STRONG },
  { word: "stolz", image: EImg.PROUD },
  { word: "suchen", image: EImg.SCOUTING },
  { word: "säen", image: EImg.SOWING },
  { word: "sägen", image: EImg.SAWING },
  { word: "säugen", image: EImg.SUCKLING },

  { word: "Tafel", image: EImg.BLACKBOARD },
  { word: "Tag" },
  { word: "Tal" },
  { word: "Tango" },
  { word: "Tanz" },
  { word: "Tasche", image: EImg.PURSE },
  { word: "Taube", image: EImg.DOVE },
  { word: "Taufe", image: EImg.BAPTISM },
  { word: "Taxi", image: EImg.TAXI },
  { word: "Teig", image: EImg.DOUGH },
  { word: "Teufel" },
  { word: "Text" },
  { word: "Tier" },
  { word: "Tiger", image: EImg.TIGER },
  { word: "Timo" },
  { word: "Tinte" },
  { word: "Tisch", image: EImg.TABLE },
  { word: "Tor", image: EImg.GOAL },
  { word: "Torte", image: EImg.PIE },
  { word: "Traum" },
  { word: "Tresor", image: EImg.VAULT },
  { word: "Träne", image: EImg.TEAR },
  { word: "Tuba", image: EImg.TUBA },
  { word: "Tube" },
  { word: "Tuch", image: EImg.TABLE_CLOTH },
  { word: "Turm", image: EImg.LIGHTHOUSE },
  { word: "Tür", image: EImg.DOOR },
  { word: "Tüte", image: EImg.BAG },
  { word: "tapfer" },

  { word: "Ufo", image: EImg.UFO },
  { /* image: EImage.image: "???", */ word: "Ufer" },
  { word: "Uwe" },

  { word: "Vase", image: EImg.VASE },
  { word: "Vater", image: EImg.FATHER },
  { word: "Virus" },
  { word: "vier", image: EImg.FOUR },

  { word: "Wabe" },
  { word: "Wal", image: EImg.WHALE },
  { word: "Walze" },
  { word: "Wange", image: EImg.CHEEK },
  { word: "warm" },
  { word: "Warze" },
  { word: "waten" },
  { word: "weben", image: EImg.WEAVE },
  { word: "weinen", image: EImg.CRY },
  { word: "Wecker" },
  { word: "Weg", image: EImg.ROAD },
  { word: "Weizen", image: EImg.WHEAT },
  { word: "weiß", image: EImg.WHITE },
  { word: "Welpe", image: EImg.PUPPY },
  { word: "Welt" },
  { word: "Wiese" }, // meadow
  { word: "Woche", image: EImg.CALENDAR_WEEK },
  { word: "Wolf", image: EImg.WOLF },
  { word: "Wolke", image: EImg.CLOUD },
  { word: "Wunde", image: EImg.WOUND },
  { word: "Wut" },
  { word: "Wäsche", image: EImg.LAUNDRY },
  { word: "Würfel" },

  /* X: currently have no words beginning with x. */

  { word: "Yak" },
  { word: "Yeti" },
  { word: "Yoga" },

  { word: "Zange", image: EImg.PLIERS },
  { word: "Zaun", image: EImg.FENCE },
  { word: "Zebra" },
  { word: "Zelt", image: EImg.TENT },
  { word: "Ziege", image: EImg.GOAT },
  { word: "Ziel" },
  { word: "Zopf", image: EImg.PIGTAIL_HAIR },
  { word: "Zug", image: EImg.TRAIN_2 },
  { word: "zeigen", image: EImg.POINTING },
  { word: "fauchen", image: EImg.HISSING },
  { word: "zwei", image: EImg.TWO },

  /* Currently have no image which start with "ä". */
  /* Ask Katrin to draw some. */
  { word: "Äcker", image: EImg.FIELDS }, // fields
  { word: "Äpfel", image: EImg.APPLES }, // apples
  { word: "Ärger" }, // anger
  { word: "Ärmel" }, // sleeve (clothes)
  { word: "Äste" }, // branches

  { word: "Öl", image: EImg.OIL },

  { word: "übel" },
  { word: "üben", image: EImg.WORKING_OUT }
];
