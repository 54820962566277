import { latinAlphabet } from "../../letter/shared";

export const sounds_de_DE = [
  ...latinAlphabet,
  "ö",
  "ä",
  "ü",
  "ß",
  "ai",
  "au",
  "ch",
  "chs",
  "ck",
  "ei",
  "eu",
  "ie",
  "ng",
  "nk",
  "pf",
  "qu",
  "sch",
  "sp",
  "st",
  "tz",
  "äu"
];
