import { ELocale } from "../../i18n";

export enum ELanguage {
  no = "no",
  se = "se"
}

export enum EUserLanguage {
  sv = "sv",
  nb = "nb",
  nn = "nn"
}

export const userLanguageToLocale: (userLanguage: EUserLanguage) => ELocale = (
  userLanguage
) => {
  switch (userLanguage) {
    case EUserLanguage.nb:
      return ELocale.nb_NO;
    case EUserLanguage.nn:
      return ELocale.nn_NO;
    case EUserLanguage.sv:
      return ELocale.sv_SE;
  }
};

export const userLanguageToLanguage: (
  userLanguage: EUserLanguage
) => ELanguage = (userLanguage) => {
  switch (userLanguage) {
    case EUserLanguage.sv:
      return ELanguage.se;
    default:
      return ELanguage.no;
  }
};

/**
 * @param language
 * @returns {EUserLanguage} - Default user language (locale) based on language
 */
export const languageToUserLanguage: (language: ELanguage) => EUserLanguage = (
  language
) => {
  switch (language) {
    case ELanguage.no:
      return EUserLanguage.nb;
    case ELanguage.se:
      return EUserLanguage.sv;
  }
};
