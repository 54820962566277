import { EImg, ETag, TTaskWord } from "../shared";

/* how to deal with letters with apostrophees? */
/* while sourcing words, treat these letters as normal letters? */

/* 1 filter for words we can use. But add image names which are obvious. */
/* 2 add english image filenames to the objects. */

/* 3 check if works with getImages. */

/* Initially include words up to length 6. */

export const task_words_es_ES: TTaskWord[] = [
  // for some reason, these are put at bottom with sort-lines.
  { word: "ábaco" },
  { word: "ábaco" }, // prev image "kuleramme"?
  { word: "ácido" },
  { word: "águila" },
  { word: "ángel" },
  { word: "árbol" },
  // specal sound ch?
  { word: "abedul" }, //bjerk
  { word: "abeja", image: EImg.BEE },
  { word: "abeto" }, // grantre
  { word: "abonar" }, // kreditere
  { word: "abraza" }, // klemme
  { word: "abrigo" }, // kappe
  { word: "abrir" }, // aapne
  { word: "abuela" }, // bestemor
  { word: "aceite" }, // olje
  { word: "acelga" }, // bladbete???
  { word: "acera" }, // fortau
  { word: "achicar" }, // oeasa(/heve?)
  { word: "actor" }, // skuespiller
  { word: "acusar" }, // beskylde
  { word: "adulto" }, // voksen
  { word: "afeito" }, // barbere/"shave"
  { word: "aflojo" }, // loesne
  { word: "afro" }, // afro; have a person with an afro.
  { word: "agenda" }, // dagbok
  { word: "agitar" }, // riste
  { word: "aguja" }, // naal? word which has something to do with water/cleaning?
  { word: "ahogar" }, // drukne?
  { word: "alarma" },
  { word: "alce", image: EImg.MOOSE },
  { word: "alegre", image: EImg.SMILE },
  { word: "alerta" }, // advare
  { word: "aleta" }, // finne
  { word: "alioli" },
  { word: "alisar" }, // glatte
  { word: "almeja" }, // blaaskjell
  { word: "altar" }, // alter
  { word: "alto", image: EImg.TALL },
  { word: "altura" }, // hoeyde
  { word: "alud", image: EImg.AVALANCHE },
  { word: "alumno", image: EImg.PUPIL },
  { word: "alzar" }, // jaesa ??
  { word: "amable" }, // elskelig
  { word: "amar" }, // else
  { word: "amigo" }, // venn
  { word: "Ana", tags: [ETag.PERSON_NAME] },
  { word: "anclar" }, // ankre?
  { word: "andar", image: EImg.WALK /* or walking? */ },
  { word: "animal" }, // dyr. for abstrakt?
  { word: "animar" }, // heie
  { word: "anotar" }, // bokfoere
  { word: "anís", image: EImg.ANISE },
  { word: "apagar" }, // slaa av
  { word: "arar", image: EImg.PLOUGH },
  { word: "arañar" }, // klore
  { word: "archivo", image: EImg.FILE }, // 7 letters...
  { word: "arco", image: EImg.BOW },
  { word: "ardilla", image: EImg.SQUIRREL },
  { word: "arena" }, // sand
  { word: "arma", image: EImg.RIFLE },
  { word: "arnés" }, // seletoey??
  { word: "arriba", image: EImg.UP },
  { word: "arroz", image: EImg.RICE },
  { word: "as", image: EImg.ACE },
  { word: "asar" }, // ovnsteke??
  { word: "asear" }, // rydde
  { word: "aseo", image: EImg.TOILET },
  { word: "asno", image: EImg.DONKEY },
  { word: "asomar" }, // stikke frem
  { word: "asusto" }, // skremme
  { word: "atacar" }, // angripe. EImg.CHASE?
  { word: "atar" }, // binde
  { word: "atlas" },
  { word: "atleta" }, // atlet
  { word: "atún" }, // tunfisk
  { word: "aula" }, // klasserom
  { word: "aullar" }, // "yla"
  { word: "autora" }, // forfatter
  { word: "avena", image: EImg.WHEAT },
  { word: "avión", image: EImg.AEROPLANE },
  { word: "ayudar", image: EImg.HELP },
  { word: "azada" }, // hakke
  { word: "azotea" }, // flatt tak
  { word: "azteca" }, // aztetisk
  { word: "azul", image: EImg.BLUE },

  { word: "baba" }, // sikkel
  { word: "babear" }, // sikle
  { word: "bailar" }, // danse
  { word: "bajar" }, // (gaa ned)/senke
  { word: "ballena", image: EImg.WHALE },
  { word: "balón", image: EImg.BALL },
  { word: "bambú" }, // bambus
  { word: "banca" }, // bankvesen?? bank?
  { word: "baraja" }, // kortstokk
  { word: "barato" }, // billig
  { word: "barba", image: EImg.BEARD },
  { word: "barca" }, // fiskebaat
  { word: "barco", image: EImg.BOAT },
  { word: "barrer" }, // "sopa"(sv?)
  { word: "barriga", image: EImg.STOMACH },
  { word: "barril" }, // toenne
  { word: "barrio" }, // nabolag
  { word: "barro" }, // leire
  { word: "basura" }, // soeppel
  { word: "bata" }, // badekaape
  { word: "batido" }, // milkshake
  { word: "baya", image: EImg.STRAWBERRY },
  // Which one can use EImg.BATHE?
  // would neeed multiple images to use all?
  { word: "bañar", image: EImg.BATHE },
  { word: "bañera", image: EImg.BATHE },
  { word: "baño", image: EImg.BATHROOM },
  { word: "baúl", image: EImg.SUITCASE }, // stor koffert?
  { word: "beber", image: EImg.DRINK },
  { word: "besar", image: EImg.KISS },
  { word: "beso", image: EImg.KISS },
  { word: "bistec" }, // biff
  { word: "blanco", image: EImg.WHITE },
  { word: "blusa" }, // bluse
  { word: "boca" }, // munn
  { word: "boda" }, // bryllupp
  { word: "bodega" }, // lasterom
  { word: "boina" }, // baskerlue
  { word: "bol" }, // bolle (som i mat?)
  { word: "bola" }, // kule
  { word: "bolera", image: EImg.BOWLING_PIN }, // image usable?
  { word: "bolo", image: EImg.BOWLING_PIN },
  { word: "bolsa", image: EImg.BAG },
  { word: "bolso", image: EImg.PURSE },
  { word: "bomba" }, // bombe
  { word: "borrar" }, // slette
  { word: "bota" }, // stoevel
  { word: "botón" }, // knapp
  { word: "boya" }, // boeye
  { word: "bozal" }, // munnkurv
  { word: "brasa" }, // glo
  { word: "brazo", image: EImg.ARM },
  { word: "broma" }, // spoek
  { word: "bronca", image: EImg.SHOUT_1 }, // kjefte?
  { word: "bronce" }, // bronse
  { word: "bruja", image: EImg.WITCH },
  { word: "bucear", image: EImg.DIVE },
  { word: "buey", image: EImg.BULL },
  { word: "bufar" }, // vaesa
  // which one to use?
  { word: "buho", image: EImg.OWL },
  { word: "búho" },
  // =================
  { word: "burro", image: EImg.DONKEY },
  { word: "bus", image: EImg.BUS },
  { word: "buscar", image: EImg.SCOUTING },
  { word: "bóveda" }, // hvelv

  { word: "caballo", image: EImg.HORSE },
  { word: "cabeza", image: EImg.HEAD },
  { word: "cable" }, // kabel
  { word: "cabra", image: EImg.GOAT },
  { word: "cacao" }, // cerat??
  { word: "cadena", image: EImg.NECKLACE },
  { word: "cadera" }, // hofte
  { word: "caduca" }, // utloept/expired
  { word: "caer" }, // falle
  { word: "café", image: EImg.COFFEE },
  { word: "cajero" }, // kasserer
  { word: "cajón", image: EImg.DRAWER },
  { word: "cala" }, // bukt
  { word: "calle", image: EImg.STREET },
  { word: "calor" }, // varm
  { word: "calzar" }, // ta paa sko
  { word: "calzón", image: EImg.PANTS },
  { word: "cama" }, // seng
  { word: "camello", image: EImg.CAMEL },
  { word: "camisa", image: EImg.SHIRT },
  { word: "camión" }, // lastebil
  { word: "canal" }, // kanal (som i baat)?
  { word: "canapé" }, // kanape?
  { word: "canino" }, //hjoertetann??
  { word: "canoa" }, // kano
  { word: "canoso" }, // graasprengt
  { word: "cansar" }, // trette ut/gjoere noe utmattet
  { word: "cantar", image: EImg.SING },
  { word: "capa", image: EImg.CAPE }, // is this the right kind of cape?
  { word: "carga" }, // belastning
  { word: "cargar" }, // belaste
  { word: "carne", image: EImg.MEAT },
  { word: "caro" }, // dyrt
  { word: "carreta" }, // kjerre
  { word: "carril" }, // vogn??
  { word: "carro" }, // vogn??
  { word: "carta", image: EImg.CARDS }, // can use img?
  { word: "cartel" }, // kartell
  { word: "casa", image: EImg.HOUSE },
  { word: "casar" }, // gifte (seg?)
  { word: "casco", image: EImg.HELMET }, // can use img?
  { word: "casero" }, // hjemmelaget
  { word: "casino" },
  { word: "castor", image: EImg.BEVER },
  { word: "cavar" }, // grave
  { word: "cazador", image: EImg.HUNTER },
  { word: "cazar" }, // jakte
  { word: "cazo" }, // oese
  { word: "caña" }, // ??
  { word: "cebo", image: EImg.BAIT },
  { word: "cebolla" }, // loek
  { word: "ceja" }, // eyebrows
  { word: "celda" }, // celle
  { word: "cena" }, // kveldsmat
  { word: "cera", image: EImg.HAIR_VAX },
  { word: "cerdo", image: EImg.PIG },
  { word: "cereal" }, // "cereal"
  { word: "cereza" }, // morell
  { word: "cerezo" }, // kirsebaertre
  { word: "cero", image: EImg.ZERO },
  { word: "cerrojo" }, // (henge-?)laas
  { word: "cesta", image: EImg.BASKET }, // can use img?
  { word: "champú", image: EImg.SHAMPOO },
  { word: "charca", image: EImg.DAM },
  { word: "chica", image: EImg.GIRL },
  { word: "chófer", image: EImg.DRIVER },
  { word: "ciego" }, // blind/kan ikke se?
  { word: "cielo" }, // himmel
  { word: "cien" }, // hundre
  { word: "ciervo" }, // ren eller hjort
  { word: "cigala", image: EImg.SCHRIMP },
  { word: "cinco", image: EImg.FIVE },
  { word: "cine", image: EImg.CINEMA },
  { word: "cinta" }, // baand
  { word: "circo" }, // sirkus
  { word: "ciudad", image: EImg.CITY }, // can use img?
  { word: "clavar" }, // nagle?
  { word: "clavo", image: EImg.NAIL }, // can use img?
  { word: "claxon", image: EImg.HORN }, // can use img?
  { word: "coche", image: EImg.CAR },
  { word: "cocina", image: EImg.KITCHEN },
  { word: "codo" }, // albue
  { word: "cofre", image: EImg.CASKET },
  { word: "coger" }, // ta
  { word: "cohete" }, // "rocket"
  { word: "cojo" }, // halt?
  { word: "cojín", image: EImg.PILLOW },
  { word: "col", image: EImg.CABBAGE },
  { word: "cola", image: EImg.QUEUE }, // or, EImg.TAIL. also koe??
  { word: "colina", image: EImg.HILL },
  { word: "collar", image: EImg.NECKLACE },
  { word: "comba" }, // hoppetau
  { word: "comer", image: EImg.EAT },
  { word: "comida", image: EImg.FOOD },
  { word: "concha", image: EImg.SHELL },
  { word: "conejo", image: EImg.RABBIT },
  { word: "cono", image: EImg.BOWLING_PIN }, // can use img?
  { word: "copa", image: EImg.TROPHY },
  { word: "copiar" }, // kopiere
  { word: "cordón" }, // snor
  { word: "coro", image: EImg.QUIRE },
  { word: "corona", image: EImg.CROWN },
  { word: "corral" }, // gaardsplass
  { word: "correr", image: EImg.RUN },
  { word: "cortar", image: EImg.CUTTING },
  { word: "coser", image: EImg.SOWING }, // can use img?
  { word: "costa", image: EImg.COAST },
  { word: "coñac" }, // cognac?
  { word: "crep" }, // "crep"/pancake
  { word: "cresta", image: EImg.COMB },
  { word: "cromo" }, // glansbilde
  { word: "cruzar" }, // to cross
  { word: "cuadra" }, // stall
  { word: "cuadro", image: EImg.CUBOID }, // can use img?
  { word: "cuarzo" }, // kvarts??
  { word: "cuatro", image: EImg.FOUR },
  { word: "cubo", image: EImg.BUCKET },
  { word: "cuchara", image: EImg.SPOON },
  { word: "cuenco" }, // bolle??
  { word: "cuento", image: EImg.BOOK_KIDS },
  { word: "cuerda", image: EImg.ROPE }, // can use img?
  { word: "cuerno", image: EImg.HORN }, // can use img?
  { word: "cuero" }, // leather??
  { word: "cuerpo" }, // kropp
  { word: "cuervo", image: EImg.RAVEN },
  { word: "cuesta" }, // bakke?? EImg.HILL?
  { word: "cueva" }, // grotte eller "idea"?
  { word: "cuidar" }, // passe paa
  { word: "cuna" }, // barneseng
  { word: "curar" }, // helbrede
  { word: "cámara" }, // kamera??
  { word: "césped", image: EImg.GRAS },

  { word: "dama" }, // dame
  { word: "dar" }, // gi
  { word: "dardo", image: EImg.ARROW },
  { word: "decir", image: EImg.SAY },
  { word: "dedal" }, // fingerboel
  { word: "dedos", image: EImg.TOES },
  { word: "delfín", image: EImg.DOLPHIN },
  { word: "diablo" }, // djevel
  { word: "diana" }, // blinke
  { word: "diente", image: EImg.TOOTH },
  { word: "diez", image: EImg.TEN },
  { word: "disco" }, // grammofonplate
  { word: "doblar" }, // brette
  { word: "doctor", image: EImg.DOCTOR },
  { word: "domar" }, // temme (noe vilt?)
  { word: "dominó" }, // domino (spillet)
  { word: "dorado" }, // gylden
  { word: "dormir", image: EImg.DOZE }, // can use img?
  { word: "dos", image: EImg.TWO },
  { word: "dragón", image: EImg.DRAGON },
  { word: "duelo" }, // duell
  { word: "duende" }, // nisse??
  { word: "dátil" }, // daddel. wat in english?

  { word: "editor" }, // forelegger?
  { word: "educar" }, // utdanne
  { word: "elevar" }, // loefte opp
  { word: "elfo" }, // elf?
  { word: "elixir", image: EImg.SERUM }, // can use img?
  { word: "encima" }, // over/"above"
  { word: "encía" }, // gomme??
  { word: "enero" }, // januar
  { word: "enfado", image: EImg.SOUR }, // can use img?
  { word: "enojar" }, // gjoere sint
  { word: "entrar" }, // gaa inn
  { word: "enviar" }, // fly (som i fugl; eller som med fly?)
  { word: "erizo" }, // pinnsvin
  { word: "ermita" }, // kapell
  { word: "escoba" }, // kost? som i "broom"?
  { word: "escudo", image: EImg.SHIELD },
  { word: "esfera" }, // sfaere??
  { word: "espada", image: EImg.SWORD },
  { word: "espejo", image: EImg.MIRROR },
  { word: "esposa" }, // kone
  { word: "esposo" }, // ektemann
  { word: "espuma" }, // (bade/barber)skum
  { word: "espía" }, // spion
  { word: "esquí", image: EImg.SKIS }, // can use img?
  { word: "estuche", image: EImg.PENCIL_CASE },
  { word: "euro" },
  { word: "examen" },

  { word: "faisán", image: EImg.PHEASANT },
  { word: "falda", image: EImg.SKIRT },
  { word: "faltar" }, // mangle
  { word: "fanta" }, // alene/solo/"alone"
  { word: "faro", image: EImg.LIGHTHOUSE },
  { word: "farola" }, // gatelykt
  { word: "felino" }, // katteaktig
  { word: "feliz", image: EImg.SMILE }, // can use img?
  { word: "fiebre", image: EImg.FEVER },
  { word: "fiesta", image: EImg.PARTY },
  { word: "filete" }, // filet?
  { word: "filmar" }, // filme
  { word: "firmar" }, // skrive under
  { word: "flaco" }, // tynn/"skinny"
  { word: "flan" }, // karamellpudding??
  { word: "flecha", image: EImg.ARROW }, // can use img?
  { word: "flotar" }, // flyte
  { word: "foca", image: EImg.SEAL },
  { word: "foco", image: EImg.PEAR },
  { word: "fogón" }, // ildsted??
  { word: "follaje", image: EImg.LEAVES }, // can use img?
  { word: "forjar" }, // smi? som i smi gjern?
  { word: "formal" }, // formell
  { word: "foto", image: EImg.PHOTO },
  { word: "frasco" }, // flaske
  { word: "frenar" }, // bremse
  { word: "fresa", image: EImg.STRAWBERRY },
  { word: "frijol" }, // boenne/"bean"
  { word: "frotar" }, // frottere??
  { word: "fruta" }, // frukt (i flertall?)
  { word: "frío" }, // kald
  { word: "fuego", image: EImg.FIREPLACE }, // can use img?
  { word: "funda" }, // overtrekk?
  { word: "fundir", image: EImg.MELTING_SNOW }, // can use img?
  { word: "furgón" }, // storvarebil??
  { word: "fábula" }, // fabel?

  { word: "gacela" }, // gasell?
  { word: "gachas", image: EImg.PORRIDGE },
  { word: "gafas", image: EImg.GLASSES },
  { word: "galgo" }, // mynde?
  { word: "galleta", image: EImg.COOKIES }, // can use img?
  { word: "gallina", image: EImg.CHICK }, // can use img?
  { word: "gallo", image: EImg.ROOSTER },
  { word: "gamba", image: EImg.SCHRIMP },
  { word: "ganado" }, // buskap?
  { word: "ganar", image: EImg.WIN },
  { word: "gancho" }, // krok? som i fiskekrok? eller som i kaptein krok?
  { word: "ganso", image: EImg.GOOSE },
  { word: "garaje" }, // garasje
  { word: "garfio" }, // krok... igjen
  { word: "garra", image: EImg.CLAW },
  { word: "gas" }, // gass
  { word: "gatear", image: EImg.CRAB },
  { word: "gato", image: EImg.CAT },
  { word: "gema" }, // eldste??
  { word: "gemelo" }, // tvilling??
  { word: "gente", image: EImg.PEOPLE }, // can use img?
  { word: "girar" }, // dreie
  { word: "globo", image: EImg.BALLOON },
  { word: "gnomo" }, // dverg
  { word: "gofre" }, // vaffel
  { word: "gol", image: EImg.GOAL }, // can use img?
  { word: "golf", image: EImg.GOLF },
  { word: "goma" }, // gummi
  { word: "gordo" }, // feit (mann)
  { word: "gorra" }, // feit (dame)
  { word: "gorro", image: EImg.HAT_1 }, // can use img?
  { word: "gota" }, // draape?
  { word: "gotear" }, // dryppe
  { word: "grabar" }, // gravere
  { word: "granja" }, // gaard
  { word: "grano" }, // gryn
  { word: "grieta", image: EImg.TEARING }, // can use img?
  { word: "gripe" }, //influensa
  { word: "gritar", image: EImg.SHOUT_2 },
  { word: "grueso" }, // tykk??
  { word: "grupo" }, // gruppe
  { word: "guapa" }, // soet (jente)
  { word: "gusano" }, // mark can use bait?

  { word: "hacha", image: EImg.AXE },
  { word: "hada", image: EImg.FAIRY },
  { word: "harina", image: EImg.FLOUR }, // can use img?
  { word: "helada", image: EImg.FREEZE }, // can use img?
  { word: "heno", image: EImg.HUH }, // can use img?
  { word: "herida", image: EImg.WOUND },
  { word: "hielo" }, // hagl
  { word: "hocico" }, // mule???
  { word: "hoguera", image: EImg.CAMPFIRE },
  { word: "hola", image: EImg.HI },
  { word: "honor" }, // honor/pride
  { word: "hueso", image: EImg.BONE },
  { word: "huevo", image: EImg.EGG },
  { word: "humo", image: EImg.SMOKE },

  { word: "iglú", image: EImg.IGLOO },
  { word: "igual" }, // lik (som i "flere ting er like"?)
  { word: "iguana" }, // iguan
  { word: "imán" },
  { word: "isla", image: EImg.ISLAND },

  { word: "jabalí" }, // villsvin
  { word: "jabón", image: EImg.SOAP },
  { word: "jaguar" },
  { word: "jamón" }, // skinke
  { word: "japón" }, // Japan
  { word: "jarabe", image: EImg.SYRUP },
  { word: "jardín", image: EImg.GARDEN },
  { word: "jarrón", image: EImg.VASE },
  { word: "jaula", image: EImg.CAGE },
  { word: "jefe", image: EImg.BOSS },
  { word: "jersey" }, // genser
  { word: "joya", image: EImg.NECKLACE }, // can use img?
  { word: "joyero", image: EImg.JEWEL }, // can use img?
  { word: "judo", image: EImg.JUDO },
  { word: "juega" }, // lek
  { word: "juez" }, // dommer
  { word: "jugar" }, // aa leke

  { word: "kayak", image: EImg.KAYAK },

  { word: "labio" }, // leppe (som i munn)
  { word: "labrar" }, // dyrke
  { word: "ladera" }, // li
  { word: "ladrar", image: EImg.BARK_DOG_1 }, // can use img?
  { word: "ladrón", image: EImg.THIEF },
  { word: "laguna" }, // innsjoe
  { word: "lampa" }, // lampe
  { word: "lana" }, // ull
  { word: "lanza" }, // lanse? som i middelalderen?
  { word: "lanzar" }, // kaste
  { word: "lapiz", image: EImg.PENCIL },
  { word: "lapón", image: EImg.SAAMI }, // can use img?
  { word: "larva", image: EImg.CATERPILLAR },
  { word: "lasaña" },
  { word: "leche", image: EImg.MILK },
  { word: "lector", image: EImg.READ }, // can use img?
  { word: "leer", image: EImg.READ },
  { word: "lego", image: EImg.LEGO },
  { word: "león", image: EImg.LION },
  { word: "libro", image: EImg.BOOK },
  { word: "licor" }, // likoer
  { word: "liebre", image: EImg.HARE }, // can use img?
  { word: "lila" },
  { word: "lima", image: EImg.LIME },
  { word: "limpio" }, // ren (som i ren og pen)
  { word: "limón" },
  { word: "lince" }, // lo_se ???
  // { word: "llama", image: EImg.LLAMA }, // can use? how is "ll" pronounced
  { word: "llave", image: EImg.KEY },
  { word: "llorar", image: EImg.CRY },
  { word: "lobo", image: EImg.WOLF },
  { word: "lona" }, // duk
  { word: "lucio" }, // gjedde
  { word: "lujo" }, // lyx??
  { word: "luna", image: EImg.MOON },
  { word: "luz", image: EImg.LIGHT }, // can use img?
  { word: "láser", image: EImg.LAZER },
  { word: "látigo" }, // pisk/"whip"

  { word: "madera", image: EImg.TREES }, // can use img?
  { word: "madre", image: EImg.MOTHER },
  { word: "mago" }, // magiker?
  { word: "maleta", image: EImg.SUITCASE },
  { word: "mando" }, // fjernkontroll
  { word: "manga", image: EImg.ARM },
  { word: "mano", image: EImg.HAND },
  { word: "manta" }, // teppe
  { word: "mantel", image: EImg.TABLE_CLOTH }, // can use img?
  { word: "mapa" }, // kart
  { word: "mar", image: EImg.OCEAN },
  { word: "marco" }, // ram? bukk? O_o
  { word: "mareo" }, // kvalme
  { word: "marrón" }, // brun (som i farge?)
  { word: "marte", image: EImg.MARS }, // can use img?
  { word: "marzo" }, // mars (kalendermaaned)
  { word: "mazo" }, // bunke
  { word: "maíz", image: EImg.CORN }, // can use img?
  { word: "mejilla" }, // kinn
  { word: "melón", image: EImg.WATERMELON },
  { word: "mesa", image: EImg.TABLE },
  { word: "metal" },
  { word: "mirar", image: EImg.SCOUTING_2 }, // can use img?
  { word: "mochila", image: EImg.BACKPACK },
  { word: "mojado", image: EImg.WET },
  { word: "mojar" }, // fukte
  { word: "moneda", image: EImg.COIN },
  { word: "monja" }, // nonne
  { word: "mono" }, // munk
  { word: "morder", image: EImg.BITE },
  { word: "mosca", image: EImg.FLY }, // can use img?
  { word: "moto" }, // motorsykkel
  { word: "mueble" }, // moebel
  { word: "muelle", image: EImg.DOCK }, // can use img?
  { word: "mugir", image: EImg.COW_SOUND },
  { word: "mula" }, // mulddyr
  { word: "muleta" }, // krykke
  { word: "muro" }, // mur
  { word: "museo" }, // museum
  { word: "musgo" }, // mose
  { word: "muslo", image: EImg.THIGH }, // can use img?
  { word: "muñeca", image: EImg.DOLL },
  { word: "más", image: EImg.PLUS },
  { word: "médico", image: EImg.DOCTOR },
  { word: "móvil", image: EImg.CELLPHONE },
  { word: "música", image: EImg.MUSIC },

  { word: "nabo" }, // nepe?? wtf is the english img name (if have)
  { word: "nadar", image: EImg.SWIMMING },
  { word: "nariz", image: EImg.NOSE },
  { word: "nata" }, // krem?? som i eggekrem?
  { word: "natura" }, // natur
  { word: "negro" }, // svart
  { word: "nevar", image: EImg.SNOWING }, // can use img?
  { word: "nevera", image: EImg.FRIDGE },
  { word: "nido", image: EImg.NEST },
  { word: "niebla", image: EImg.MIST },
  { word: "nieve", image: EImg.SNOWING }, // can use img?
  { word: "niña", image: EImg.GIRL },
  { word: "niño", image: EImg.BOY },
  { word: "niños" }, // gutter
  { word: "noche", image: EImg.NIGHT },
  { word: "nota", image: EImg.NOTE },
  { word: "novio", image: EImg.KISS }, // can use img?
  { word: "nube", image: EImg.CLOUD },
  { word: "nuca", image: EImg.NECK },
  { word: "nudo" }, // naken? thought we have img for this already...
  { word: "nueve", image: EImg.NINE },
  { word: "nuevo" }, // ny
  { word: "nuez", image: EImg.NUT },
  { word: "nutria" }, // is oter and bever the same?

  { word: "oasis" }, // oase
  { word: "obispo" }, // biskop
  { word: "obrero" }, // arbeider
  { word: "oca", image: EImg.GOOSE }, // can use img?
  { word: "ocho" }, // "eight"
  { word: "ogro", image: EImg.TROLL },
  { word: "ojo", image: EImg.EYE },
  { word: "ola", image: EImg.WAVE }, // can use img?
  { word: "oler", image: EImg.SMELL }, // can use img?
  { word: "oliva" }, // oliven
  { word: "olivo" }, // oliventre
  { word: "once", image: EImg.ELEVEN },
  { word: "orca" }, // spekkhogger
  { word: "oreja", image: EImg.EAR },
  { word: "oreo" }, // oreo-kjekks
  { word: "oro", image: EImg.GOLD },
  { word: "oso", image: EImg.BEAR },
  { word: "otoño", image: EImg.AUTUMN },
  { word: "oveja", image: EImg.SHEEP }, // can use img?
  { word: "ovni", image: EImg.UFO },

  { word: "padre", image: EImg.FATHER },
  { word: "pagar" }, // betale
  { word: "paja" }, // gressstraa
  { word: "pajar", image: EImg.HAY }, // can use img?
  { word: "pala", image: EImg.SPADE },
  { word: "palo" }, // pinne
  { word: "paloma", image: EImg.DOVE },
  { word: "pan" }, // broed/"loaf of bread"
  { word: "panda" },
  { word: "papel" }, // ark/papir
  { word: "pascua" }, // paaske
  { word: "pasta" },
  { word: "pastel", image: EImg.CAKE },
  { word: "pastor" }, // gjeter. think have img?
  { word: "pata", image: EImg.PAW },
  { word: "patata", image: EImg.POTATO },
  { word: "patio" }, // gaards???
  { word: "pato", image: EImg.DUCK },
  { word: "pavo" }, // kalkun -> turkey?
  { word: "país", image: EImg.CITY }, // can use img?
  { word: "pedal", image: EImg.PEDAL },
  { word: "pedo", image: EImg.FART },
  { word: "pegar" }, // klebe??
  { word: "peinar", image: EImg.COMBING }, // can use img?
  { word: "peine", image: EImg.COMB },
  { word: "pelar" }, // skrelle
  { word: "pelo", image: EImg.HAIR },
  { word: "pelota", image: EImg.BALL_2 }, // can use img?
  { word: "peluca" }, // loeshaar???
  { word: "peluche", image: EImg.TEDDY_BEAR },
  { word: "pensar" }, // tenke
  { word: "pera", image: EImg.PEAR },
  { word: "perdiz" }, // rapphoene??
  { word: "perla" }, // perle
  { word: "perro", image: EImg.DOG },
  { word: "pescar", image: EImg.FISHING },
  { word: "peso" }, // peso (penger)
  { word: "pezuña", image: EImg.HOOF },
  { word: "piano", image: EImg.PIANO },
  { word: "picar" }, // stikke
  { word: "pico", image: EImg.BEAK },
  { word: "pie", image: EImg.FOOT }, // can use img?
  { word: "piel" }, // hud
  { word: "piloto", image: EImg.SEA_PILOT },
  { word: "pincel" }, // pensel (som i male med pensel?)
  { word: "pino" }, // furu
  { word: "pintar" }, // ri (hest)
  { word: "pinza", image: EImg.PINCH },
  { word: "piojo", image: EImg.LICE }, // can use img?
  { word: "pipa", image: EImg.PIPE },
  { word: "pirata", image: EImg.PIRATE_1 },
  { word: "piña" }, // ananas
  { word: "plata" }, // soelv
  { word: "plato", image: EImg.PLATE },
  { word: "playa" }, // strand
  { word: "plaza" }, // "square"?
  { word: "plomo" }, // bly
  { word: "pluma", image: EImg.FEATHER },
  { word: "poeta" }, // dikter
  { word: "pollo", image: EImg.CHICK },
  { word: "polvo" }, // pulver
  { word: "pomelo", image: EImg.GRAPES }, // can use img?
  { word: "postal" }, // brevkort??
  { word: "potro" }, // pony/foell (som i ung hest?)
  { word: "pozo" }, // broenn
  { word: "prado", image: EImg.FIELDS }, // can use img?
  { word: "premio" }, // premie
  { word: "profe", image: EImg.TEACHER },
  { word: "pueblo" }, // landsby
  { word: "puente", image: EImg.BRIDGE },
  { word: "puerta", image: EImg.DOOR },
  { word: "pulpo" }, // squid/blekksprut
  { word: "puma", image: EImg.PUMA },
  { word: "puño", image: EImg.FIST },
  { word: "pájaro" }, // fugl?

  { word: "queso", image: EImg.CHEESE },
  { word: "quilo", image: EImg.KILO },
  { word: "qué", image: EImg.HUH }, // can use img?

  { word: "radio", image: EImg.RADIO },
  { word: "rajar", image: EImg.TEARING }, // can use img?
  { word: "rampa" }, // rampe?? "ramp?" for aa hoppe med f.eks. bil??
  { word: "rana", image: EImg.TOAD }, // can use img?
  { word: "rascar" }, // skrape
  { word: "rata" }, // raatte
  { word: "ratón", image: EImg.TEARING }, // can use img?
  { word: "rayo", image: EImg.LIGHTNING },
  { word: "red", image: EImg.FISHING_NET }, // can use img?
  { word: "regalo", image: EImg.PRESENT },
  { word: "regla" }, // regel?
  { word: "reloj", image: EImg.WATCH }, // can use img?
  { word: "remar", image: EImg.ROWING },
  { word: "remo", image: EImg.PADDLE },
  { word: "rey" }, // konge
  { word: "rezar", image: EImg.PRAY },
  { word: "reír", image: EImg.LAUGH }, // can use img?
  { word: "rico", image: EImg.RICH },
  { word: "rizo" }, // kroelle
  { word: "roca", image: EImg.STONE },
  { word: "rodilla", image: EImg.KNEE },
  { word: "rodillo" }, // kvevle? think have img for this already
  { word: "rojo", image: EImg.RED },
  { word: "romper" }, // oedelegge?
  { word: "roncar" }, // snorke
  { word: "rosa", image: EImg.PINK },
  { word: "rosal" }, // rosebusk
  { word: "rueda", image: EImg.WHEEL }, // can use img?
  { word: "ruido", image: EImg.SOUND }, // can use img?
  { word: "ruleta" }, // rullett (kasinospillet?)
  { word: "río", image: EImg.RIVER },

  { word: "safari" },
  { word: "sal" }, // salt
  { word: "salida", image: EImg.EXIT },
  { word: "salmón", image: EImg.SALMON },
  { word: "salsa", image: EImg.SAUCE }, // can use img?
  { word: "saltar", image: EImg.JUMPING },
  { word: "salón" }, // stue
  { word: "sandía", image: EImg.WATERMELON }, // can use img?
  { word: "sapo", image: EImg.TOAD },
  { word: "sauna" }, // badstue
  { word: "seda" }, // silke
  { word: "seis", image: EImg.SIX },
  { word: "selva" }, // jungel
  { word: "serrar", image: EImg.SAWING },
  { word: "seta", image: EImg.MUSHROOM },
  { word: "señal", image: EImg.SIGN }, // can use img?
  { word: "sidra", image: EImg.CIDER },
  { word: "siete", image: EImg.SEVEN },
  { word: "silla", image: EImg.CHAIR },
  { word: "silo", image: EImg.SILO },
  { word: "sirena" }, // sirene (lyd?)
  { word: "soda", image: EImg.SODA },
  // Which one to use?
  { word: "sofa", image: EImg.SOFA },
  { word: "sofá" },
  { word: "sol", image: EImg.SUN },
  { word: "soldar" }, // lodde (elektronikk)
  { word: "sombra", image: EImg.SHADOW },
  { word: "sonido", image: EImg.SOUND }, // can use img?
  { word: "sopa" }, // suppe
  { word: "soplar" }, // blaase (med munn, eller vind?)
  { word: "soñar" }, // droemme
  { word: "subir" }, // gaa oppover?
  { word: "sucio" }, // moekkete
  { word: "sudar" }, // svette
  { word: "suegro" }, // svigerfar
  { word: "suelo", image: EImg.INCLINE }, // can use img?
  { word: "surf" }, // surfe
  { word: "sábana" }, // laken

  { word: "tacos" }, // tacos (mat)
  { word: "talar" }, // hogge? (traer?)
  { word: "tambor", image: EImg.DRUM }, // can use img?
  { word: "tarro" }, // krukke
  { word: "tarta", image: EImg.CAKE },
  { word: "taxi", image: EImg.TAXI },
  { word: "teatro" }, // teater
  { word: "tebeo", image: EImg.COMIC }, // can use img?
  { word: "techo", image: EImg.ROOF },
  { word: "tecla", image: EImg.BUTTON },
  { word: "tejado", image: EImg.ROOF }, // which roof-word to use?
  { word: "tejer" }, // strikke
  { word: "tejido", image: EImg.WEAVE },
  { word: "tela" }, // stoff
  { word: "telar" }, // vevstol
  { word: "templo" }, // tempel
  { word: "tenis" }, // tennis
  { word: "tesoro", image: EImg.TREASURE },
  { word: "tienda" }, // butikk
  { word: "tigre", image: EImg.TIGER },
  { word: "timbal" }, // pauke???
  { word: "timbre" }, // doerklokke
  { word: "tina", image: EImg.TUB }, // can use img?
  { word: "tirar" }, // kaste? hvilken?
  { word: "tiza" }, // kritt?
  { word: "tomate", image: EImg.TOMATO },
  { word: "topo", image: EImg.MULE }, // can use img?
  { word: "toro", image: EImg.BULL },
  { word: "torta" }, // terte??
  { word: "traje" }, // (f.eks. same-)drakt?
  { word: "trapo" }, // fille
  { word: "trece" }, // tretten (13)
  { word: "tren", image: EImg.TRAIN },
  { word: "trenza" }, // flette (haar?)
  { word: "tres", image: EImg.THREE },
  { word: "trigo", image: EImg.WHEAT }, // can use img?
  { word: "trineo", image: EImg.SLED },
  { word: "trueno", image: EImg.LIGHTNING }, // can use img?
  { word: "trufa" }, // troeffel??
  { word: "trébol" }, // kloever?
  { word: "tuba", image: EImg.TUBA },
  { word: "tubo", image: EImg.PIPE }, // can use img?
  { word: "tuerca", image: EImg.NUT_MECHANICAL },
  { word: "túnel", image: EImg.TUNNEL },

  { word: "uno", image: EImg.ONE },
  { word: "untar" }, // smoere??
  { word: "uvas", image: EImg.GRAPES },
  { word: "uña", image: EImg.NAIL },

  { word: "vaca", image: EImg.COW },
  { word: "vacas", image: EImg.COWS },
  { word: "vaciar" }, // toemme??
  { word: "vago" }, // vag?
  { word: "valla", image: EImg.FENCE },
  { word: "vapor", image: EImg.STEAM }, // can use img?
  { word: "vaso", image: EImg.GLAS }, // can use img?
  { word: "vela", image: EImg.LIGHTBULB }, // can use img?
  { word: "vena" }, // blodaare
  { word: "venda" }, // bind??
  { word: "veneno", image: EImg.POISON },
  { word: "ver", image: EImg.SCOUTING_2 }, // can use img?
  { word: "verde", image: EImg.GREEN },
  { word: "viejo" }, // gammel
  { word: "vino" }, // vin
  { word: "viola" }, // bratsj??
  { word: "violín", image: EImg.VIOLIN },
  { word: "volar", image: EImg.FLYING }, // can use img?

  // { word: "xico" }, // valenciano?

  { word: "yeso" }, // think have img, but dont remember what it is called
  { word: "yoyo", image: EImg.YOYO },
  { word: "yoga", image: EImg.YOGA }, // can use work?

  { word: "zapato", image: EImg.SHOE },
  { word: "zorro" }, // need img.
  { word: "zumo" },

  { word: "ópera" }
];
