import { EImg, ETag, TTaskWord } from "../shared";

export const task_words_sv_SE: TTaskWord[] = [
  { word: "and", image: EImg.DUCK },
  { word: "anis", image: EImg.ANISE },
  { word: "apa", image: EImg.MONKEY },
  { word: "arm", image: EImg.ARM },

  { word: "bada", image: EImg.BATHE },
  { word: "baka", image: EImg.BAKE },
  { word: "balja", image: EImg.TUB },
  { word: "banan", image: EImg.BANANA },
  { word: "barn", image: EImg.BABY },
  { word: "ben", image: EImg.BONE },
  { word: "bi", image: EImg.BEE },
  { word: "bil", image: EImg.CAR },
  { word: "bild", image: EImg.PICTURES },
  { word: "bita", image: EImg.BITE },
  { word: "björn", image: EImg.BEAR },
  { word: "blixt", image: EImg.LIGHTNING },
  { word: "blå", image: EImg.BLUE },
  { word: "bok", image: EImg.BOOK },
  { word: "brev", image: EImg.LETTER },
  { word: "bro", image: EImg.BRIDGE },
  { word: "bur", image: EImg.CAGE },
  { word: "byxa", image: EImg.PANTS },
  { word: "bär", image: EImg.STRAWBERRY },
  { word: "bäver", image: EImg.BEVER } /* English image name is wrong. */,
  { word: "böja", image: EImg.BEND },
  { word: "båge", image: EImg.BOW },
  { word: "bål", image: EImg.CAMPFIRE },
  { word: "båt", image: EImg.BOAT },

  { word: "cerat", image: EImg.LIPGLOSS }, // Rename image? Lip something?
  { word: "cider", image: EImg.CIDER },
  { word: "cykel", image: EImg.BICYCLE, tags: [ETag.MISSING_SOUND_FILE] },

  { word: "deg", image: EImg.DOUGH },
  { word: "dia", image: EImg.SUCKLING },
  { word: "dop", image: EImg.BAPTISM },
  { word: "drake", image: EImg.DRAGON },
  { word: "druva", image: EImg.GRAPES },
  { word: "duk", image: EImg.TABLE_CLOTH },
  { word: "duva", image: EImg.DOVE },
  { word: "dyka", image: EImg.DIVE },

  { word: "elev", image: EImg.PUPIL },
  { word: "Eli", image: EImg.NAME_HAAKON, tags: [ETag.PERSON_NAME] },
  { word: "exit", image: EImg.EXIT },

  { word: "fana", image: EImg.PENNANT },
  { word: "far", image: EImg.FATHER },
  { word: "fasan", image: EImg.PHEASANT },
  { word: "fat", image: EImg.PLATE },
  { word: "fe", image: EImg.FAIRY },
  { word: "feber", image: EImg.FEVER },
  { word: "fem", image: EImg.FIVE },
  { word: "fil", image: EImg.FILE },
  { word: "film", image: EImg.FILM },
  { word: "fiol", image: EImg.VIOLIN },
  { word: "fis", image: EImg.FART },
  { word: "fisk", image: EImg.FISH },
  { word: "fixa", image: EImg.REPAIR },
  { word: "fluga", image: EImg.FLY },
  { word: "flyg", image: EImg.AEROPLANE },
  { word: "fot", image: EImg.FOOT },
  { word: "foto", image: EImg.PHOTO },
  { word: "frysa", image: EImg.FREEZE },
  { word: "furu", image: EImg.TREES },
  { word: "fyr", image: EImg.LIGHTHOUSE },
  { word: "fyra", image: EImg.FOUR },
  { word: "föl", image: EImg.FOAL_1 },
  { word: "fön", image: EImg.HAIR_DRYER },
  { word: "får", image: EImg.SHEEP },

  { word: "gala", image: EImg.CROWING },
  { word: "gap", image: EImg.GAPE },
  { word: "gata", image: EImg.STREET },
  { word: "godis", image: EImg.SWEETS },
  { word: "gris", image: EImg.PIG },
  { word: "gräs", image: EImg.GRAS },
  { word: "gröt", image: EImg.PORRIDGE },
  { word: "gråta", image: EImg.CRY },
  { word: "gul", image: EImg.YELLOW },
  { word: "gå", image: EImg.WALK },
  { word: "gås", image: EImg.GOOSE },
  { word: "gåva", image: EImg.PRESENT },

  { word: "haj", image: EImg.SHARK },
  { word: "hand", image: EImg.HAND },
  { word: "hare", image: EImg.HARE },
  { word: "hav", image: EImg.OCEAN },
  { word: "horn", image: EImg.HORN },
  { word: "hov", image: EImg.HOOF },
  { word: "hund", image: EImg.DOG },
  { word: "hus", image: EImg.HOUSE },
  { word: "huvud", image: EImg.HEAD },
  { word: "hälsa", image: EImg.HANDSHAKE },
  { word: "häst", image: EImg.HORSE },
  { word: "häxa", image: EImg.WITCH },
  { word: "hö", image: EImg.HAY },
  { word: "höna", image: EImg.HEN },
  { word: "höra", image: EImg.HEAR },
  { word: "höst", image: EImg.AUTUMN },
  { word: "Håkan", image: EImg.NAME_EVEN, tags: [ETag.PERSON_NAME] },
  { word: "hål", image: EImg.HOLE },
  { word: "hår", image: EImg.HAIR },
  { word: "håv", image: EImg.HAND_NET },

  { word: "Ida", image: EImg.NAME_IDA, tags: [ETag.PERSON_NAME] },
  { word: "ide", image: EImg.HIBERNATING },
  { word: "iglo", image: EImg.IGLOO },
  { word: "is", image: EImg.ICE_CUBE },

  { word: "jaga", image: EImg.CHASING },
  { word: "jojo", image: EImg.YOYO },
  { word: "judo", image: EImg.JUDO },
  { word: "jul", image: EImg.CHRISTMAS } /* Should be Jul (captial "j")? */,
  { word: "juvel", image: EImg.JEWEL },
  { word: "jäsa", image: EImg.RISE },

  { word: "kaj", image: EImg.DOCK_2 },
  { word: "kajak", image: EImg.KAYAK },
  { word: "kaka", image: EImg.CAKE },
  { word: "kam", image: EImg.COMB },
  { word: "kamel", image: EImg.CAMEL },
  { word: "kanin", image: EImg.RABBIT },
  { word: "kanot", image: EImg.KAYAK }, // image "kayak" used for both "kajak" and "kanot". */
  { word: "kavaj", image: EImg.COAT },
  { word: "kex", image: EImg.COOKIES },
  { word: "klo", image: EImg.CLAW },
  { word: "kniv", image: EImg.KNIFE },
  { word: "knyta", image: EImg.TYING },
  { word: "knä", image: EImg.KNEE },
  { word: "ko", image: EImg.COW },
  { word: "koka", image: EImg.BOIL },
  { word: "kort", image: EImg.CARDS },
  { word: "korv", image: EImg.SAUSAUGE } /* Image name is wrong? */,
  { word: "krok", image: EImg.PEG },
  { word: "krona", image: EImg.CROWN },
  { word: "kråka", image: EImg.CROW },
  { word: "kula", image: EImg.BALL_2 },
  { word: "kö", image: EImg.QUEUE },
  { word: "kör", image: EImg.QUIRE },
  { word: "kål", image: EImg.CABBAGE },

  { word: "lada", image: EImg.BARN },
  { word: "lama", image: EImg.LLAMA },
  { word: "larv", image: EImg.CATERPILLAR },
  { word: "laser", image: EImg.LAZER },
  { word: "lax", image: EImg.SALMON },
  { word: "le", image: EImg.SMILE },
  { word: "ler", image: EImg.LAUGH } /* TODO: remove? same-same as le */,
  { word: "lego", image: EImg.LEGO },
  { word: "lejon", image: EImg.LION },
  { word: "leta", image: EImg.SCOUTING },
  { word: "lie", image: EImg.SCYTHE },
  { word: "lila", image: EImg.PURPLE },
  { word: "lim", image: EImg.GLUE },
  { word: "limpa", image: EImg.SLICE_OF_BREAD },
  { word: "lo", image: EImg.LYNX },
  { word: "lok", image: EImg.TRAIN },
  { word: "lots", image: EImg.SEA_PILOT },
  { word: "lus", image: EImg.LICE },
  { word: "lysa", image: EImg.LIGHT },
  { word: "lyx", image: EImg.LUXURY },
  { word: "läsa", image: EImg.READ },
  { word: "lök", image: EImg.ONION },
  { word: "löpa", image: EImg.RUN },
  { word: "löv", image: EImg.LEAVES },
  { word: "lår", image: EImg.THIGH },
  { word: "lås", image: EImg.PADLOCK },

  { word: "mage", image: EImg.STOMACH },
  { word: "mars", image: EImg.MARS },
  { word: "mat", image: EImg.FOOD },
  { word: "melon", image: EImg.WATERMELON },
  { word: "mjöl", image: EImg.FLOUR },
  { word: "mjölk", image: EImg.MILK },
  { word: "mobil", image: EImg.CELLPHONE },
  { word: "moped", image: EImg.MOPED },
  { word: "mor", image: EImg.MOTHER },
  { word: "mos", image: EImg.MASHED_POTATOES },
  { word: "mule", image: EImg.MULE },
  { word: "mur", image: EImg.BRICK_WALL },
  { word: "mus", image: EImg.MOUSE },
  { word: "musik", image: EImg.MUSIC },
  { word: "mynt", image: EImg.COIN },
  { word: "mål", image: EImg.GOAL },
  { word: "måla", image: EImg.PAINT },
  { word: "måne", image: EImg.MOON },
  { word: "mås", image: EImg.SEAGULL },

  { word: "navel", image: EImg.BELLY_BUTTON },
  { word: "nio", image: EImg.NINE },
  { word: "not", image: EImg.NOTE },
  { word: "nypa", image: EImg.PINCH },
  { word: "nysa", image: EImg.SNEEZE },
  { word: "näsa", image: EImg.NOSE },
  { word: "näve", image: EImg.FIST },
  { word: "nöt", image: EImg.NUT },
  { word: "nål", image: EImg.NEEDLE },

  { word: "Ola", image: EImg.NAME_OLE, tags: [ETag.PERSON_NAME] },
  { word: "os", image: EImg.STEAM },
  { word: "ost", image: EImg.CHEESE },
  { word: "otur", image: EImg.UNLUCKY },
  { word: "oxe", image: EImg.BULL },

  { word: "paj", image: EImg.PIE },
  { word: "pedal", image: EImg.PEDAL },
  { word: "peka", image: EImg.POINTING },
  { word: "piano", image: EImg.PIANO },
  { word: "pil", image: EImg.ARROW },
  { word: "pipa", image: EImg.PIPE },
  { word: "pir", image: EImg.BREAKWATER },
  { word: "pirat", image: EImg.PIRATE_1 },
  { word: "pokal", image: EImg.TROPHY },
  { word: "polis", image: EImg.POLICE },
  // { word: "potatis", image: EImg.POTATO } /* TODO: remove? too long. */,
  { word: "prins", image: EImg.PRINCE },
  { word: "puma", image: EImg.PUMA },
  { word: "päron", image: EImg.PEAR },
  { word: "påse", image: EImg.BAG },

  { word: "radio", image: EImg.RADIO },
  { word: "ram", image: EImg.FRAME } /* Rename to picture_frame. */,
  { word: "ras", image: EImg.AVALANCHE },
  { word: "ren", image: EImg.REINDEER },
  { word: "rep", image: EImg.ROPE },
  { word: "rida", image: EImg.RIDE },
  { word: "rik", image: EImg.RICH },
  /* TODO: figure out which image was used in old image "system". */
  { word: "rim" },
  { word: "ris", image: EImg.RICE },
  // { word: "Rita", image: EImg.NAME_RITA, tags: [ETag.PERSON_NAME] },
  { word: "rita", image: EImg.PAINT_PICTURE },
  { word: "riva", image: EImg.TEARING },
  { word: "ro", image: EImg.ROWING, tags: [ETag.MISSING_SOUND_FILE] },
  { word: "ropa", image: EImg.SHOUT_2 },
  { word: "ros", image: EImg.ROSE },
  { word: "rum", image: EImg.ROOM },
  { word: "räka", image: EImg.SCHRIMP } /* Should be shrimp? */,
  { word: "räv", image: EImg.FOX },
  { word: "röd", image: EImg.RED },
  { word: "rök", image: EImg.SMOKE },
  { word: "rör", image: EImg.WATERPIPE },

  { word: "sadel", image: EImg.SADDLE },
  { word: "same", image: EImg.SAAMI },
  { word: "sax", image: EImg.SCISSORS },
  { word: "se", image: EImg.WATCHING },
  { word: "sele", image: EImg.BUCKLE },
  { word: "sex", image: EImg.SIX },
  { word: "sil", image: EImg.STRAINER },
  { word: "silo", image: EImg.SILO },
  { word: "sko", image: EImg.SHOE },
  { word: "skruv", image: EImg.SCREW },
  { word: "skåp", image: EImg.CABINET },
  /* TODO: figure out which image was used in old image "system". */
  { word: "slev" /* image: ""} */ },
  { word: "snö", image: EImg.SNOWING },
  { word: "Sofia", image: EImg.NAME_EVA, tags: [ETag.PERSON_NAME] },
  { word: "sol", image: EImg.SUN },
  { word: "såpa", image: EImg.SOAP },
  { word: "spik", image: EImg.NAIL },
  { word: "stege", image: EImg.LADDER },
  { word: "stol", image: EImg.CHAIR },
  { word: "sur", image: EImg.SOUR },
  { word: "svans", image: EImg.TAIL },
  { word: "säga", image: EImg.SPEAK },
  { word: "sy", image: EImg.SEW },
  { word: "säl", image: EImg.SEAL },
  { word: "så", image: EImg.SOWING },
  { word: "såg", image: EImg.SAW },
  { word: "såga", image: EImg.SAWING },
  { word: "sår", image: EImg.WOUND },

  { word: "tak", image: EImg.ROOF },
  { word: "tala", image: EImg.SPEAK },
  { word: "taxi", image: EImg.TAXI },
  { word: "tiger", image: EImg.TIGER },
  { word: "tio", image: EImg.TEN },
  { word: "tomat", image: EImg.TOMATO },
  { word: "tre", image: EImg.THREE },
  { word: "tuba", image: EImg.TUBA },
  { word: "tuta", image: EImg.HONK },
  { word: "två", image: EImg.TWO },
  { word: "tvål", image: EImg.SOAP },
  { word: "tält", image: EImg.TENT },
  { word: "tö", image: EImg.MELTING_SNOW },
  { word: "tår", image: EImg.TOES },

  { word: "ubåt", image: EImg.SUBMARINE },
  { word: "ufo", image: EImg.UFO },
  { word: "ur", image: EImg.WATCH },

  /* TODO: figure out which image was used in old image "system". */
  { word: "vad" /* , image: */ }, // TODO: back of leg.
  { word: "val", image: EImg.WHALE },
  { word: "vas", image: EImg.VASE },
  { word: "vax", image: EImg.HAIR_VAX },
  { word: "vete", image: EImg.WHEAT },
  { word: "vikt", image: EImg.SCALE },
  { word: "vit", image: EImg.WHITE },
  { word: "väg", image: EImg.ROAD },
  { word: "väsa", image: EImg.HISSING },
  { word: "väska", image: EImg.PURSE },
  { word: "väv", image: EImg.WEAVE },
  { word: "våg", image: EImg.WAVE },
  { word: "våt", image: EImg.WET },

  { word: "yla", image: EImg.HOWL },
  { word: "ysta", image: EImg.CURDING },
  { word: "yxa", image: EImg.AXE },

  { word: "älv", image: EImg.RIVER },
  { word: "älva", image: EImg.ELEVEN },
  { word: "ära", image: EImg.PROUD },
  /* TODO: figure out which image was used in old image "system". */
  { word: "ärm" /* , image: "" */ },
  { word: "äta", image: EImg.EAT },

  { word: "öga", image: EImg.EYE },
  { word: "öra", image: EImg.EAR },
  { word: "örn", image: EImg.EAGLE },
  { word: "ösa", image: EImg.SCOOP_WATER },
  { word: "öva", image: EImg.WORKING_OUT },

  { word: "ål", image: EImg.EEL },
  { word: "åra", image: EImg.PADDLE },
  { word: "ås", image: EImg.HILL },

  /* TODO: ask a Swede which images should be used. */
  /* TODO: put images into sorted section of array. */
  { word: "ask" /* image: "" */ },
  { word: "Be" /* image:"" */ },
  { word: "bo" /* image:"" */ },
  { word: "bu" /* image:"" */ },
  { word: "bår" /* image:"" */ },
  { word: "dam" /* image:"" */ },
  { word: "dela" /* image:"" */ },
  { word: "dun" /* image:"" */ },
  { word: "ek" /* image:"" */ },
  { word: "eka" /* image:"" */ },
  { word: "elva", image: EImg.ELEVEN },
  // { word: "älva", image: EImg.FAIRY }, // TODO: verify if should use
  { word: "Emil", tags: [ETag.PERSON_NAME] },
  { word: "Eva", tags: [ETag.PERSON_NAME] },
  { word: "fisa" /* image:"" */ },
  { word: "gasa" /* image:"" */ },
  { word: "hamn" /* image:"" */ },
  { word: "jamar" /* image:"" */ },
  { word: "kavel" /* image:"" */ },
  { word: "kiwi" /* image:"" */ },
  { word: "krama" /* image:"" */ },
  { word: "lakan" /* image:"" */ },
  { word: "lika" /* image:"" */ },
  { word: "Lina", tags: [ETag.PERSON_NAME] },
  { word: "log" /* image:"" */ },
  { word: "luva" /* image:"" */ },
  { word: "meta" /* image:"" */ },
  { word: "mu" /* image:"" */ },
  { word: "mäta" /* image:"" */ },
  { word: "ner" /* image:"" */ },
  { word: "oboj" /* image:"" */ },
  { word: "ola" /* image:"" */ },
  { word: "ont" /* image:"" */ },
  { word: "osams" /* image:"" */ },
  { word: "plog" /* image:"" */ },
  { word: "pär" /* image:"" */ },
  { word: "pöl" /* image:"" */ },
  { word: "resa" /* image:"" */ },
  { word: "rev" /* image:"" */ },
  { word: "sal" /* image:"" */ },
  { word: "snor" /* image:"" */ },
  { word: "suga" /* image:"" */ },
  { word: "surf" /* image:"" */ },
  { word: "sås" /* image:"" */ },
  { word: "te" /* image:"" */ },
  { word: "torka" /* image:"" */ },
  { word: "uno" /* image:"" */ },
  { word: "vika" /* image:"" */ },
  { word: "värpa" /* image:"" */ },
  { word: "ömsa" /* image:"" */ },
  { word: "ådra" /* image:"" */ },
  { word: "åsna" /* image: "" */ }
];
