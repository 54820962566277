import { ELocale, LocaleRecord } from "../../i18n";
import { task_words_de_DE } from "./locale/de_DE";
import { task_words_es_ES } from "./locale/es_ES";
import { sharedNorwegian, task_words_nb_NO } from "./locale/nb_NO";
import { task_words_nn_NO } from "./locale/nn_NO";
import { task_words_sv_SE } from "./locale/sv_SV";
import { TTaskWord } from "./shared";

export * from "./shared";
export * from "./trie";
export * from "./wordImage";
export * from "./word";
export * from "./word/legacyFnsGerman";

export const getTaskWords = (l: ELocale) => {
  const taskWordsByLocale: LocaleRecord<TTaskWord[]> = {
    de_DE: task_words_de_DE,
    es_ES: task_words_es_ES,
    nb_NO: task_words_nb_NO,
    nn_NO: task_words_nn_NO,
    sv_SE: task_words_sv_SE
  };
  return taskWordsByLocale[l] ?? [];
};

export const getSharedNorwegianWordsMap: () => {
  [word: string]: true | undefined;
} = () => Object.fromEntries(sharedNorwegian.map(({ word }) => [word, true]));
