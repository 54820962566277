import * as R from "ramda";
import { ELocale } from "../../../i18n";
import { TSound } from "../../sound";
import { TTaskWord } from "../shared";
import { getSoundsInWord } from "../word";

/* Write list with all word filtering variants for imal tasks. */
/* Will also have other word sourcing scenarios, where won't use trie. */
/* - find words with images */
/* - find words with known sounds */
/*   - support max length and min length */
/* - find words with specific sound */
/* - find words which start with sound */

export type Node = {
  /* Enjoy this a lot; simplifies capitalization, getting imagebytes for this */
  /* task word, etc. */
  taskWord?: TTaskWord; // maybe even add sounds array to task word?
  leaves: Node[];
  sound: string;
};

type TNewNode = (pNode?: Partial<Node>) => Node;

/* Doesn't have to be public. */
const newNode: TNewNode = (pNode) => ({
  leaves: [],
  sound: "",
  ...pNode
});

const recAddWord = (node: Node, remaining: TSound[], taskWord: TTaskWord) => {
  if (remaining.length === 0) {
    node.taskWord = taskWord;
    return;
  }
  let nextNode = node.leaves.find((n) => n.sound === R.head(remaining));
  if (!nextNode) {
    nextNode = newNode({ sound: R.head(remaining) });
    node.leaves.push(nextNode);
  }
  recAddWord(nextNode, R.tail(remaining), taskWord);
};

type TNewTrie = (words?: TTaskWord[], locale?: ELocale) => Node;

export const newTrie: TNewTrie = (words = [], locale) => {
  const trie = newNode();
  words.forEach((tw) =>
    recAddWord(
      trie,
      locale ? getSoundsInWord(locale, tw.word) : tw.word.split(""),
      tw
    )
  );
  return trie;
};

/* Having both addWord & recAddWord; simplifies adjusting exported API. */
export const addWord = (trie: Node, tw: TTaskWord, locale?: ELocale) => {
  if (tw.word === "") return;
  recAddWord(
    trie,
    locale ? getSoundsInWord(locale, tw.word) : tw.word.split(""),
    tw
  );
};

type TFindWithSound = (n: Node, sound: string, wordAcc?: string) => TTaskWord[];

/* This won't find words which dont have sound last... */
export const findWithSound: TFindWithSound = (n, sound) =>
  n.sound === sound
    ? getTaskWords(n)
    : n.leaves.reduce(
        (acc, leaf) => acc.concat(findWithSound(leaf, sound)),
        [] as TTaskWord[]
      );

type TFindWithSounds = (
  n: Node,
  sounds: string[],
  wordAcc?: string
) => TTaskWord[];

/* Find words in trie where all sounds are members of `sounds`.  */
export const findWithSounds: TFindWithSounds = (n, sounds) => {
  return !sounds.includes(n.sound) && n.sound !== ""
    ? []
    : n.leaves.reduce(
        (acc, leaf) => acc.concat(findWithSounds(leaf, sounds)),
        n.taskWord ? [n.taskWord] : []
      );
};

type TGetTaskWords = (node: Node) => TTaskWord[];
export const getTaskWords: TGetTaskWords = (node) => {
  return node.leaves.reduce(
    (acc, leaf) => acc.concat(getTaskWords(leaf)),
    node.taskWord ? [node.taskWord] : []
  );
};

type TSize = (trie: Node) => number;

export const size: TSize = (trie) =>
  trie.leaves.reduce((acc, leaf) => acc + size(leaf), trie.taskWord ? 1 : 0);

/* Need to keep track of indentation and mode(arrow-mode?) during trie traversal. */
export const toString = (
  node = newNode(),
  depth = 0,
  lineAcc = "",
  arrowKind = "straight" as "straight" | "bent"
) => {
  if (depth === 0) return node.sound;
  return "foobar";
};

/* TODO: create some print fn? */
/* Something like this:     */
/* l-o-l                    */
/* |                        */
/* +-m-a-o                  */
/* | | |                    */
/* | | +-a-o                */
/* | |                      */
/* | +-m-m-a-o              */
/* | |                      */
/* | +-f-a-o                */
/* |                        */
/* +-o-v-e                  */

/* Where to put this? Construct after locale is selected? (by using locales TaskWord[]) */
/* Why? Because word string value will be the id of the word (to get other props of the word). */
// const taskWordByWord: Record<string, TTaskWord> = {
//   cow: {
//     word: "cow",
//     image: EImg.COW
//   }
// };
