import PouchDB from "pouchdb";
import * as R from "ramda";
import UAParser from "ua-parser-js";
import { getTaskWords } from ".";
import { db } from "../../firebase";
import { ELocale, LocaleRecord } from "../../i18n";
import {
  Base64,
  ChecksumMD5,
  FileName,
  getChecksum,
  Merge,
  parseEnumValues
} from "../../ts";
import { EImg, ImgWord, TTaskWord, TWord } from "./shared";

/* TODO: find a fix for cache updating issue? */
/* Meh... works second time user visits site after cache version */
/* has changed. Will porbably never happen anyway so idgaf. */

/* Unit test this stuff? Will probably be very helpful the day we decide */
/* to simplify these monster functions. */

type Image = Base64;
type ImageName = string;

type TGetCachedImages = (imageNames: ImageName[]) => Promise<CachedImage[]>;

type TUpdateCache = (
  imageNames: Array<ImageName> // (all image names; cached and uncached)
) => Promise<ImageByImageName>;

type TGetDbImageChecksums = () => Promise<Record<FileName, ChecksumMD5>>;

type TGetCachedImage = (imageName: ImageName) => Promise<CachedImage | null>;

type CachedImage = {
  imageName: ImageName;
  bytes: Base64;
  checksum: ChecksumMD5;
};

type TCacheImage = (args: {
  bytes: Base64;
  imageName: string;
}) => Promise<void>;

type ImageByImageName = Record<ImageName, Image>;

const cache = new PouchDB("wordImages");

const CACHE_VERSION = "0";

const getCacheVersion = () => localStorage.getItem("CACHE_VERSION_WORD_IMAGES");

const updateCacheVersion = () => {
  localStorage.setItem("CACHE_VERSION_WORD_IMAGES", CACHE_VERSION);
};

const validateCache = async () => {
  if (getCacheVersion() !== CACHE_VERSION) {
    for (const doc of (await cache.allDocs()).rows) {
      try {
        await cache.remove({ _id: doc.id, _rev: doc.value.rev });
      } catch (e) {
        console.log(`Failed deleting existing image ${doc.id} from cache.`);
      }
    }
    updateCacheVersion();
  }
};

const cacheImage: TCacheImage = async ({ bytes, imageName }) => {
  let existing: any;
  try {
    existing = await cache.get(imageName, { latest: true });
    if (existing) cache.remove(imageName, existing._rev);
  } catch (e) {
    /* ... */
  }

  try {
    cache.put(
      {
        _id: imageName,
        bytes,
        checksum: getChecksum(bytes) // Might be slowing things down?
        // Could potentially be passed down from db checksum doc.
      },
      { force: true }
    );
  } catch (e) {
    console.log(`Error caching image "${imageName}".`);
    console.log(e);
  }
};

const getCachedImage: TGetCachedImage = async (imageName) => {
  let response;
  try {
    response = (await cache.get(imageName)) as any;
  } catch (e) {
    return null;
  }

  return {
    bytes: response.bytes,
    checksum: response.checksum,
    imageName: response._id
  } as CachedImage;
};

const getPriorityImageNames = (locale: ELocale) => {
  const imageNamesByLocale: LocaleRecord<TWord[]> = {
    de_DE: [
      "sheep",
      "bever",
      "rose",
      "car",
      "planet_earth",
      "train_2",
      "casket",
      "moon",
      "pink",
      "lion"
    ]
  };
  return imageNamesByLocale[locale] ?? [];
};

const getDbImageChecksums: TGetDbImageChecksums = async () =>
  (
    await db.collection("taskWordImages").doc(",checksumByImageName").get()
  ).data() as Record<FileName, ChecksumMD5>;

/* Compare checksums of cached- and db images. */
/* When different, overwrite cached image with the new image from db. */
const updateCache: TUpdateCache = async (imageNames) => {
  const cachedImages = await getCachedImages(imageNames);

  const dbChecksums = await getDbImageChecksums();

  return await cachedImages.reduce(async (acc, cachedImage) => {
    if (cachedImage.checksum === dbChecksums[cachedImage.imageName] ?? "")
      return acc;
    const imageBytes = await getDbImage(cachedImage.imageName);
    if (!imageBytes) return acc;
    return { ...acc, [cachedImage.imageName]: imageBytes };
  }, Promise.resolve({} as ImageByImageName));
};

type TGetDbImage = (name: ImageName) => Promise<Base64 | null>;

const getDbImage: TGetDbImage = async (imageName) => {
  const bytes =
    (await db.collection("taskWordImages").doc(imageName).get()).data()
      ?.base64 ?? null;
  if (!bytes) {
    console.log(
      `Warning: db does not contain image named ${imageName}.\nEither, image name is misspelled, or image is missing from db.`
    );
    return null;
  }
  cacheImage({ bytes, imageName });
  return bytes;
};

const getCachedImages: TGetCachedImages = async (imageNames) =>
  (await Promise.all(imageNames.map(getCachedImage))).filter(
    R.identity
  ) as CachedImage[];

type TExecGetImagesParams = {
  state?: Record<TWord, Base64>;
  // Rename to onLoadImage?
  setState?: (
    value: (value: Record<TWord, Base64>) => Record<TWord, Base64>
  ) => void;
  collectStats?: boolean;
};

export type TExecGetImagesStatsSources =
  | { [key in EBytesSource]: number }
  | undefined;

export type TExecGetImagesStats =
  | {
      sources: TExecGetImagesStatsSources;
      seconds: number;
      metadata: {
        browserName: string | undefined;
        browserVersion: string | undefined;
        deviceVendor: string | undefined;
        deviceModel: string | undefined;
        deviceType: string | undefined;
        osName: string | undefined;
        osVersion: string | undefined;
      };
    }
  | undefined;

export type TExecGetImagesResponse = {
  images: Record<TWord, Base64>;
  stats: TExecGetImagesStats;
};

export enum EBytesSource {
  remote = "remote",
  cached = "cached",
  memory = "memory",
  none = "none"
}

type TGenerateFirebaseValueString = {
  simpleUserAgent?: string;
  performanceSource?: string;
  value: string | undefined;
};

export const createAnalyticsDataFromStats: (
  stats: TExecGetImagesStats
) => TGenerateFirebaseValueString = (stats) => {
  try {
    if (!!stats) {
      const { metadata, sources, seconds } = stats;
      if (!!sources) {
        const { remote, cached, memory } = sources;
        const { browserName, browserVersion, osName, osVersion, deviceModel } =
          metadata;
        const simpleUserAgent = `${
          !!deviceModel ? `${deviceModel}_` : ""
        }${osName} (${osVersion})_${browserName} (${browserVersion})`;
        const performanceSource = `${seconds}s${
          remote > 0 ? `_${remote}r` : ""
        }${cached > 0 ? `_${cached}c` : ""}${memory > 0 ? `_${memory}m` : ""}`;
        return {
          simpleUserAgent,
          performanceSource,
          value: `${performanceSource}_${simpleUserAgent}`
        };
      }
    }
  } catch (err) {
    console.error("Could not generate stats string", err);
  }
  return {
    value: typeof stats?.seconds === "number" ? `${stats.seconds}s` : undefined
  };
};

// Does this fn have to be so large? Currently over 100 lines long...
/**
 * A function to retrieve a dictionary of words and corresponding images (base64 data)
 *  from either browser cache or FireStore DB.
 * @param locale - Locale to decide what words to map images from
 * @param params -
 *  A getState and a setState to be used with a `useState`,
 *  so that you can publish the dictionary for each entry (word -> image data).
 *  Remember that you have to provide both a getState and a setState if you want to use this with a state,
 *  or else it will only return the entire dictionary at the end.
 * @returns {Record<TWord, Base64>} -
 *  A dictionary of words and image data,
 *  where the words are chosen by the provided locale
 */
export const execGetImages: (
  locale: ELocale,
  params?: TExecGetImagesParams
) => Promise<TExecGetImagesResponse> = async (locale, params) => {
  const { state, setState, collectStats } = params ?? {};
  const imageByWord: Record<TWord, Base64> = {};
  const useWithState = !!state && !!setState;
  const imageSourceArray: EBytesSource[] = [];
  let stats: TExecGetImagesStats = undefined;

  const addImageToState = (word: TWord, image: Base64) => {
    if (useWithState) {
      setState((prev) => ({ ...prev, [word]: image }));
    } else {
      imageByWord[word] = image;
    }
  };

  const allTws = getTaskWords(locale);

  /* Uniq should not be neccessary? Should not have duplicates. */
  const taskWords = R.uniq(allTws.filter((tw) => R.has("image", tw))) as Merge<
    TTaskWord,
    { image: EImg }
  >[];

  const getWordImageAndAddToState: (imgWord: ImgWord) => Promise<EBytesSource> =
    async ({ image: imageName, word }) => {
      const cachedBytes = (await getCachedImage(imageName))?.bytes;
      if (cachedBytes) {
        addImageToState(word, cachedBytes);
        return EBytesSource.cached;
      }
      const dbBytes = await getDbImage(imageName);
      if (dbBytes) {
        addImageToState(word, dbBytes);
        return EBytesSource.remote;
      }
      return EBytesSource.none;
    };

  /* 1. */
  await validateCache();

  const start = collectStats ? Date.now() : 0;

  /* 2. Get images. */
  /* Load some images first for "snappier" experience, if any images are prioritized. */
  const priorityImages = getPriorityImageNames(locale);
  if (priorityImages.length > 0) {
    const priImages = await Promise.all(
      (
        priorityImages
          .map((img) => taskWords.find((tw) => tw.image === img))
          .filter(R.identity) as ImgWord[]
      ).map(getWordImageAndAddToState)
    );
    if (collectStats) {
      imageSourceArray.push(...priImages);
    }
  }

  // Fetch remaining images.
  const remainingImages = await Promise.all(
    taskWords.map((tw) =>
      (useWithState ? state : imageByWord)[tw.word]
        ? Promise.resolve(EBytesSource.memory)
        : getWordImageAndAddToState(tw)
    )
  );
  if (collectStats) {
    imageSourceArray.push(...remainingImages);
    const res = new UAParser().getResult();
    stats = {
      sources: Object.fromEntries(
        parseEnumValues(EBytesSource).map((source) => [
          source,
          imageSourceArray.filter((source1) => source === source1).length
        ])
      ),
      seconds: Math.round((Date.now() - start) / 1000),
      metadata: {
        browserName: res?.browser?.name,
        browserVersion: res?.browser?.version,
        deviceVendor: res?.device?.vendor,
        deviceModel: res?.device?.model,
        deviceType: res?.device?.type,
        osName: res?.os?.name,
        osVersion: res?.os?.version
      }
    };
    // console.log("[execGetImages] stats", stats);
  }

  /* Probably wanna create a new async version while still providing old version? -> incremental refactoring. */
  const localeTWs = await getTaskWords(locale);

  /* 3. Update cache, and add updated images to state. */
  R.toPairs(await updateCache(taskWords.map((tw) => tw.image))).forEach(
    ([imageName, bytes]) => {
      const word = localeTWs.find((ltw) => ltw.image === imageName)?.word;
      if (word) addImageToState(word, bytes);
    }
  );

  return {
    images: useWithState ? state : imageByWord,
    stats
  };
};
