import {
  ELanguage,
  EUserLanguage,
  languageToUserLanguage,
  userLanguageToLanguage
} from "../no-sv";
import { parseEnumValues } from "./record";

/* This should go in module i18n? */

/**
 * Attempts to guess language based on window location href.
 * Only works if domain name suffix matches one of the `ELanguage` values.
 * @param fallbackLanguage - The fallback language if it failed to guess
 * @returns {ELanguage} - Guessed language, `orElse` or `ELanguage.no`
 */
export const getDefaultLanguageBasedOnWindowLocation: (
  fallbackLanguage?: ELanguage
) => ELanguage = (fallbackLanguage) => {
  const defaultUserLanguage = fallbackLanguage ?? ELanguage.no;
  try {
    const localeBasedOnLocation = new RegExp(
      `.+\\.(${parseEnumValues(ELanguage).join("|")})\\/?.*`
    ).exec(window.location.href);
    if (localeBasedOnLocation === null) {
      return defaultUserLanguage;
    }
    return localeBasedOnLocation[1] as ELanguage; // Returns the value of the first (and only) group
  } catch (err) {}
  return defaultUserLanguage;
};

/**
 * Attempts to guess user language based on window location href.
 * If it is able to successfully guess, it returns the default user language (locale) based on the guessed language.
 * @param fallbackUserLanguage - The fallback user language if it failed to guess
 * @returns {EUserLanguage} - Guessed user language, `orElse` or `EUserLanguage.nb`
 */
export const getDefaultUserLanguageBasedOnWindowLocation: (
  fallbackUserLanguage?: EUserLanguage
) => EUserLanguage = (fallbackUserLanguage) => {
  return languageToUserLanguage(
    getDefaultLanguageBasedOnWindowLocation(
      fallbackUserLanguage
        ? userLanguageToLanguage(fallbackUserLanguage)
        : undefined
    )
  );
};
