import * as R from "ramda";
import { TLetter } from "../letter/shared";

/* Aka. capitalization, but that's too long :) */
export enum ECap {
  FIRST_UPPER = "firstUpper", // unneccessary? Discuss with Saskia&Teresa. Might also rename to `capitalized`.
  NORMAL = "normal", // lowercase doesn't work well with german nouns and person names...
  UPPER = "upper"
}

/* Maybe could have a fn which checks if a words first letter is capital. */
/* IDK, can just keep as is--applyCap will probably only ever be relevant */
/* for turning "LowerCased" words in "UPPER_CASED"; */
/* ie. lowercased/original words are already in correct lowercased format. */
export const applyCap = (str: string, cap: ECap) => {
  /* Edge case. */
  if (str === "ß") return "ß"; // Same glyph for large and small.

  // ?????
  // if (cap === ECap.ALL_LOWER && R.head(str) === R.head(str).toUpperCase())
  //   return str;

  if (cap === ECap.UPPER) return R.toUpper(str); // does this work with scharf-s?
  if (cap === ECap.FIRST_UPPER)
    return R.concat(R.toUpper(R.head(str)), R.tail(str));

  return str;
};

/* TODO: test this. */
const withoutCaps = ["ß"];

export const getCap = (str: string) => {
  const caps = str
    .split("")
    .map((char) =>
      withoutCaps.includes(char)
        ? null
        : char.toLocaleLowerCase() === char
        ? ECap.NORMAL
        : ECap.UPPER
    )
    .filter((e) => e);
  if (caps.every((cap) => cap === ECap.NORMAL)) return ECap.NORMAL;
  if (caps.every((cap) => cap === ECap.UPPER)) return ECap.UPPER;
  return ECap.FIRST_UPPER;
};

/* TODO: test. */
/* Non-ascii characters, scharf-s. Might have */
/* to replace `toUpperCase` with another version. */
/* And does this tie into `getCap`? */
export const isCapitalized = (word: string) =>
  word.length > 0 && R.head(word) === R.head(word).toUpperCase();

/* Does this work for "ä", "ö" etc.? should probably use a regex */
/* here too, like `isUpperCase`. */
export const isLowercase = (s: string) => R.toLower(s) === s;

export const isUpperCase = (letter: TLetter) =>
  !!/[A-ZÀ-ÖØ-ÞΆΈ-ΏΑ-ΫϢϤßϦϨϪϬϮϴϷϹϺϽ-Ͽ]/.exec(letter[0]);
