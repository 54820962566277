import { PassportUser } from "../user";

export enum Endpoint {
  rest = "rest",
  auth = "auth"
}

export type SessionPassportUser = {
  passport?: {
    user?: PassportUser;
  };
};
