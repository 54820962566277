import * as R from "ramda";
import { ELocale, LocaleRecord } from "../../i18n";
import { getVowels } from "../letter";
import { TSound } from "../sound";

export type TSyllable = string;

export type VowelPosition = "first" | "last";
export type SoundPair = [TSound, TSound];

/* Think some of the things exported here are meant to only be used locally. */

/* Not used outside? */
export const getVowelIndex = (syllable: SoundPair, locale: ELocale) =>
  hasOnlyVowels(syllable[0], locale) ? 0 : 1;

export const getForcedVowelPos = (l: ELocale, s: TSound) => {
  const edgeCasesByLocale: LocaleRecord<Record<TSound, VowelPosition>> = {
    [ELocale.de_DE]: {
      ch: "first",
      ck: "first",
      ng: "first",
      nk: "first",
      qu: "last",
      sp: "last",
      st: "last",
      ["ß"]: "first"
    },
    [ELocale.en_US]: {}
  };

  return (edgeCasesByLocale[l] ?? {})[s];
};

export const isEdgeCaseSound = (locale: ELocale, sound: TSound) =>
  !!getForcedVowelPos(locale, sound);

export const hasEdgeCaseSound = (
  l: ELocale,
  [soundA, soundB]: [TSound, TSound]
) => isEdgeCaseSound(l, soundA) || isEdgeCaseSound(l, soundB);

/* All diphtongs ("ei", "au"...) are "onlyVowels", but some "onlyVowels" are not diphtongs ("ie"). */
export const hasOnlyVowels = (sound: TSound, locale: ELocale) =>
  sound
    .split("")
    .reduce(
      (acc, letter) => (getVowels(locale).includes(letter) ? acc : false),
      true
    );

/* Handles edge cases where sounds may not be first or last in a syllable. */
export const arrangeSyllable = (
  syllable: [TSound, TSound], // Vowel & non-vowel in any order.
  vowelPosition: VowelPosition,
  locale: ELocale
) => {
  const [consonant, vowel] = hasOnlyVowels(syllable[1], locale)
    ? syllable
    : (R.reverse(syllable) as [string, string]);

  /* Should work so long as vowels/sounds with only vowels; ie. don't have edge cases. */
  const finalVowelPos = hasEdgeCaseSound(locale, syllable)
    ? getForcedVowelPos(locale, consonant)
    : vowelPosition;

  return finalVowelPos === "first"
    ? `${vowel}${consonant}`
    : `${consonant}${vowel}`;
};
