import { sharedNorwegian } from "./nb_NO";
import { EImg, ETag, TTaskWord } from "../shared";

export const task_words_nn_NO: TTaskWord[] = [
  ...sharedNorwegian,
  // { word: "auge", image: EImg.EYE, tags: [ETag.DIPHTHONG] },

  { word: "bada", image: EImg.BATHE, tags: [ETag.MISSING_SOUND_FILE] },
  { word: "boge", image: EImg.BOW, tags: [ETag.MISSING_SOUND_FILE] },

  { word: "bru", image: EImg.BRIDGE },

  // { word: "ein", image: EImg.ONE, tags: [ETag.DIPHTHONG] },

  // { word: "feiar", image: EImg.CHIMNEY_SWEEP, tags: [ETag.DIPHTHONG] },
  { word: "fluge", image: EImg.FLY },
  { word: "fønar", image: EImg.HAIR_DRYER },

  // { word: "graut", image: EImg.PORRIDGE, tags: [ETag.DIPHTHONG] },
  { word: "gut", image: EImg.BOY },
  { word: "gåve", image: EImg.PRESENT },

  { word: "hand", image: EImg.HAND },
  { word: "helse", image: EImg.HANDSHAKE },
  { word: "hovud", image: EImg.HEAD },
  { word: "høgde", image: EImg.HIGH_JUMP },
  // { word: "høyre", image: EImg.RIGHT, tags: [ETag.DIPHTHONG] },

  { word: "Ida", /*  image: EImg.NAME_IDA */ tags: [ETag.PERSON_NAME] },

  { word: "jul", image: EImg.CHRISTMAS },

  // { word: "leite", image: EImg.HIDE_AND_SEEK, tags: [ETag.DIPHTHONG] },
  { word: "lærar", image: EImg.TEACHER },

  { word: "nase", image: EImg.NOSE },
  // { word: "peike", image: EImg.POINTING, tags: [ETag.DIPHTHONG] },
  // { word: "røyr", image: EImg.WATERPIPE, tags: [ETag.DIPHTHONG] },

  { word: "røvar", image: EImg.PIRATE_2, tags: [ETag.MISSING_SOUND_FILE] },

  // { word: "seie", image: EImg.SAY, tags: [ETag.DIPHTHONG] },
  { word: "sju", image: EImg.SEVEN },
  { word: "såg", image: EImg.WATCHING },

  { word: "tjuv", image: EImg.THIEF, tags: [ETag.MISSING_SOUND_FILE] },

  { word: "veg", image: EImg.ROAD }

  // { word: "øyre", image: EImg.EAR, tags: [ETag.DIPHTHONG] }
];
